@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, hero--innerpage) {
    @include abstract.prefix-class(nobel, clip) {
        @include abstract.mq(min, tablet, sm) {
            max-width: 50%;
            width: 100%;

            @include abstract.prefix-class(nobel, clip) {
                display: flex;
                flex-flow: wrap column;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 90%;
                height: 100%;
                padding-right: 30px;

                & > article {
                    // width: 100%;
                    max-width: 75%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@include abstract.prefix-class(nobel, about) {
    @include abstract.prefix-class(nobel, section__title) {
        margin-bottom: 25px;

        & + p {
            margin-bottom: 50px;
        }
    }
}

@include abstract.prefix-class(nobel, program) {
    .nobel-section__title {
        display: none;
    }
    .nobel-header-all {
        display: block;
    }

    &.nobel-program--undergraduate {
        .nobel-header-all {
            display: none;
        }
        .nobel-header-undergraduate {
            display: block;
        }
    }

    &.nobel-program--postgraduate {
        .nobel-header-all {
            display: none;
        }
        .nobel-header-postgraduate {
            display: block;
        }
    }

    &.nobel-program--mch {
        .nobel-header-all {
            display: none;
        }
        .nobel-header-mch {
            display: block;
        }
    }
}