@use '../abstract/' as abstract;

// Mixin for prefix-class
// @include abstract.prefix-class(nobel, btn)
// @include inner-class($type: #{-}, $className: some-class-name)
// @include hover-state

@include abstract.prefix-class(nobel, divider) {
    @include abstract.display(block);
    // width: 100%;
    // height: 1px;
    @include abstract.getColor(background-color, divider, source-blue);

    @include abstract.inner-class($type: #{ -- }, $className: vertical) {
        width: 1px;
        height: 100px;
    }

    @include abstract.inner-class($type: #{ -- }, $className: horizontal) {
        width: 100%;
        height: 1px;
    }

    @include abstract.inner-class($type: #{ -- }, $className: section) {
        margin-bottom: 50px;
    }
}