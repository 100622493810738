@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, hero--home) {
    max-height: 1000px;

    @include abstract.mq(min, tablet, md) {
        height: 1000px;
        padding: 8rem 0 0;

        @include abstract.prefix-class(nobel, hero__article) {
            max-width: 85%;
        }
    }

    @include abstract.prefix-class(nobel, btn) {
        @include abstract.hover-state {
            @include abstract.getColor(background-color, whitish, source);
            @include abstract.getColor(color, primary, source);
        }
    }

    @include abstract.prefix-class(nobel, clip--inner-top-point) {
        z-index: 2;
        position: relative;

        @include abstract.prefix-class(nobel, card) {
            @include abstract.nth(2) {
                padding-top: 40px;

                .nobel-icon {
                    @include abstract.fetchGlobalVars(
                        $prop: font-size, 
                        $flag: typography, 
                        $varname: abstract.$fonts, 
                        $type: size, 
                        $output: xl
                    );
                }

                @include abstract.mq(min, tablet, sm) {
                    padding-top: 100px;
                }

                @include abstract.mq(min, desktop, sm) {
                    padding-top: 200px;
                }
            }

            @include abstract.prefix-class(nobel, btn) {
                border: 1px solid transparent;
                @include abstract.hover-state {
                    @include abstract.getColor(background-color, primary, source);
                    @include abstract.getColor(color, whitish, source);
                    border: 1px solid;
                    @include abstract.getColor(border-color, whitish, dim-40-precent);
                }
            }

            @include abstract.mq(min, tablet, sm) {
                padding-bottom: 50px;
            }
        }
    }
}

@include abstract.prefix-class(nobel, msg) {
    padding-bottom: 0 !important;
}

@include abstract.prefix-class(nobel, news) {
    padding-top: 60px !important;
}

@include abstract.prefix-class(nobel, gallery) {
    
}