@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, clip) {
    @include abstract.mq(min, tablet, sm) {
        overflow: hidden;
    }
}

@include abstract.prefix-class(nobel, clip--inner-top-point) {
    @include abstract.mq(min, tablet, sm) {
        clip-path: polygon(50% 26%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}

@include abstract.prefix-class(nobel, clip--parallelogram-right) {
    @include abstract.mq(min, tablet, sm) {
        clip-path: polygon(0 0, 100% 0, 76% 100%, 0% 100%);
    }
}