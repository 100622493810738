@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, breadcrumb) {
    @include abstract.inner-class(#{--}, section) {
        padding: 30px 0 0;
    }

    a {
        @include abstract.getColor(color, primary, source);
        
        @include abstract.hover-state {
            @include abstract.getColor(color, primary, 400);
        }
    }

    span {
        @include abstract.getColor(color, blackish, 100);
    }
    
    li {
        position: relative;
    }
    
    li + li:before {
        content: '/';
        display: inline-block;
        padding: 0 10px;
        @include abstract.getColor(color, blackish, 100);
    }
    
    padding-bottom: 30px;
    border-bottom: 1px dashed transparent;
    @include abstract.getColor(border-color, blackish, 100);
}