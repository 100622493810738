@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, element--overlay) {
    position: relative;

    @include abstract.prefix-class(nobel, container) {
        position: relative;
        z-index: 2;

        & * {
            @include abstract.getColor(color, whitish, source);
        }
    }

    @include abstract.pseudo(after, null, absolute) {
        top: 0;
        left: 0;
        z-index: 1;
        
        width: 100%;
        height: 100%;
        background-color: rgba(map-get(map-get(abstract.$colors, blackish), source), 0.7);
        background-color: var(--clr-blackish--dim-70-percent);
    }
}

@include abstract.prefix-class(nobel, component--overlay) {
    position: relative;

    & > * {
        position: relative;
        z-index: 2;

        & * {
            @include abstract.getColor(color, whitish, source);
        }
    }

    @include abstract.pseudo(after, null, absolute) {
        top: 0;
        left: 0;
        z-index: 1;
        
        width: 100%;
        height: 100%;
        background-color: rgba(map-get(map-get(abstract.$colors, blackish), source), 0.7);
        background-color: var(--clr-blackish--dim-70-percent);
    }
    
    @include abstract.inner-class(#{--}, bg-primary--source) {
        @include abstract.pseudo(after, null, absolute) {
            background-color: rgba(map-get(map-get(abstract.$colors, primary), source), 0.7);
            background-color: var(--clr-primary--dim-70-percent);
        }
    }
}