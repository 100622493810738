
@use '../library/colors' as color;
@use '../library/typography' as typo;
@use '../library/breakpoint' as bp;
@use '../library/display' as d;
@use '../library/radius' as radius;

@mixin getColor($prop, $chooseColor, $output, $imp:false) {
    @if($imp) {
        #{$prop}: map-get(map-get(color.$colors, $chooseColor), $output) !important;
        #{$prop}: var(--clr-#{$chooseColor}--#{$output}) !important;
    }
    @else {
        #{$prop}: map-get(map-get(color.$colors, $chooseColor), $output);
        #{$prop}: var(--clr-#{$chooseColor}--#{$output});
    }
}

@mixin getShadow($prop, $chooseShadow, $output, $imp:false) {
    @if($imp) {
        #{$prop}: map-get(map-get(color.$bx-shadow, $chooseShadow), $output) !important;
        #{$prop}: var(--bx-shadow-#{$chooseShadow}--#{$output}) !important;
    }
    @else {
        #{$prop}: map-get(map-get(color.$bx-shadow, $chooseShadow), $output);
        #{$prop}: var(--bx-shadow-#{$chooseShadow}--#{$output});
    }
}

@function rem($varName) {
    @return #{map-get(map-get(typo.$fonts, size), $varName)/map-get(map-get(typo.$fonts, size), base)};
}
@mixin fetchGlobalVars($prop, $flag, $varname, $type, $output, $imp:false) {
    @if($flag == color) {
        @if($imp == true) {
            #{$prop}: map-get(map-get($varname, $type), $output) !important;
            #{$prop}: var(--clr-#{$type}--#{$output}) !important;
        } @else {
            #{$prop}: map-get(map-get($varname, $type), $output);
            #{$prop}: var(--clr-#{$type}--#{$output});
        }
    }

    @if($flag == typography) {
        @if($type == size) {
            @if($imp == true) {
                #{$prop}: map-get(map-get($varname, $type), $output)+px !important;
                #{$prop}: var(--font-#{$type}--#{$output}) !important;
            } @else {
                #{$prop}: map-get(map-get($varname, $type), $output)+px;
                #{$prop}: var(--font-#{$type}--#{$output});
            }
        }
        @else {
            @if($imp == true) {
                #{$prop}: map-get(map-get($varname, $type), $output) !important;
                #{$prop}: var(--font-#{$type}--#{$output}) !important;
            } @else {
                #{$prop}: map-get(map-get($varname, $type), $output);
                #{$prop}: var(--font-#{$type}--#{$output});
            }
        }
    }
}
@mixin display($type, $imp:false) {
    @if($imp == true) {
        display: map-get(d.$display, $type) !important; 
        display: var(--d-#{$type}) !important;
    } @else {
        display: map-get(d.$display, $type); 
        display: var(--d-#{$type});
    }
}
// @include display($prop, $type, $imp:false);

@mixin prefix-class($prefix, $className) {
    .#{$prefix}-#{$className} {
        @content;
    }
}

@mixin mq($flag:min, $device, $width1, $width2:null) {
    @if ($flag == min) {
        @media only screen and (min-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) {
            @content;
        }
    }
    
    @if($flag == max) {
        @media only screen and (max-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) {
            @content;
        }
    }
    
    @if($flag == min-max) {
        @media only screen and (min-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) and (max-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width2})) {
            @content;
        }
    }
}

@mixin inner-class($type, $className) {
    &#{$type}#{$className} {
        @content;
    }
}

@mixin same-elm-class($prefix:null, $className) {
    @if($prefix) {
        &.#{$prefix}-#{$className} {
            @content;
        }
    } @else {
        &.#{$className} {
            @content;
        }
    }
}

@mixin class-injection($parent:null, $attr, $className) {
    @if ($parent) {
        .#{$parent} &#{$attr}#{$className} {
            @content;
        }
    } @else {
        &#{$attr}#{$className} {
            @content;
        }
    }
}

@mixin hover-state {
    &:hover, &:focus {
        @content;
    }
}
// @include inner-class($type: #{-}, $className: some-class-name)
// @include hover-state

@mixin pseudo($loc: before, $cnt:null, $pos: absolute) {
    position: relative;
    @if($loc == before) {
        &:before {
            @if ($cnt == null) {
                content: '';
            } @else {
                content: '#{$cnt}';
            }
            position: $pos;
            @content;
        }
    } @else {
        &:after {
            @if ($cnt == null) {
                content: '';
            } @else {
                content: '#{$cnt}';
            }
            position: $pos;
            @content;
        }
    }
}

@mixin nth($val) {
    &:nth-child(#{$val}) {
        @content;
    }
}

@mixin rounded($radius) {
    border-radius: $radius;
}

@mixin getRounded($prop, $value) {
    #{$prop}: map-get(radius.$rounded, $value);
    #{$prop}: var(--rounded--#{$value});
}

@mixin shadow($x, $y, $blur, $shade:null, $color) {
    @if($shade != null) {
        box-shadow: $x $y $blur $shade $color;
    }
    @else {
        box-shadow: $x $y $blur $color;
    }
}


@mixin plus-attr-class($className, $prefix:null) {
    @if($prefix) {
        & + .#{$prefix}-#{$className} {
            @content;
        }
    } @else {
        & + #{$className} {
            @content;
        }
    }
}

@mixin translate($axis:x, $cords) {
    @if($axis == x) {
        transform: translateX($cords);
    }
    @else if($axis == y) {
        transform: translateY($cords);
    }
    @else {
        transform: translate($cords, $cords);
    }
}