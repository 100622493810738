@use '../../abstract/' as abstract;
:root {
    @each $color, $shades in abstract.$colors {
        @each $shade, $value in $shades {
            /* #{$color}--#{$shade} */
            --clr-#{ $color }--#{ $shade }: #{ $value };
        }
    }

    @each $color, $shades in abstract.$bx-shadow {
        @each $shade, $value in $shades {
            /* --bx-shadow-#{$color}--#{$shade} */
            --bx-shadow-#{ $color }--#{ $shade }: #{ $value };
        }
    }

    @each $key, $value in abstract.$rounded {
        /* border-radius : --#{$key}--#{$value} */
        --rounded--#{$key}: #{$value};
    }

    @each $font, $types in abstract.$fonts {
        @each $type, $value in $types {
            @if($font == size) {
                /* #{$font}--#{$type} */
                --font-#{ $font }--#{ $type }: #{ $value+'px' };
            } @else {
                /* #{$font}--#{$type} */
                --font-#{ $font }--#{ $type }: #{ $value };
            }
        }
    }

    @each $type, $attr in abstract.$display {
        /* display--#{$type} */
        --d-#{ $type }: #{ $attr };
    }

    @for $i from 1 through 12 {
        --col-#{$i}--of-12: #{percentage($i / 12)};
    }
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    font-size: map-get(map-get(abstract.$fonts, size), base)*1px;
    font-size: var(--font-size--base)+'px';
}

body {
    @include abstract.getColor(background-color, whitish, source);
    @include abstract.getColor(color, text, source);
    @include abstract.fetchGlobalVars(font-family, $flag: typography, $varname: abstract.$fonts, $type: family, $output: montserrat);
    font-size: abstract.rem(base)+'rem';

    @include abstract.mq($flag: min, $device: tablet, $width1: md) {
        font-size: abstract.rem(sm)+'rem';
    }
}