:root {
  /* primary--100 */
  --clr-primary--100: #fb463c;
  /* primary--200 */
  --clr-primary--200: #fa160a;
  /* primary--300 */
  --clr-primary--300: #cd0e04;
  /* primary--400 */
  --clr-primary--400: #4f0703;
  /* primary--500 */
  --clr-primary--500: #690702;
  /* primary--dim-55-percent */
  --clr-primary--dim-55-percent: rgba(105, 7, 2, 0.55);
  /* primary--dim-70-percent */
  --clr-primary--dim-70-percent: rgba(105, 7, 2, 0.7);
  /* primary--dim-85-percent */
  --clr-primary--dim-85-percent: rgba(105, 7, 2, 0.85);
  /* primary--source */
  --clr-primary--source: #690702;
  /* accent--100 */
  --clr-accent--100: #39a6f9;
  /* accent--200 */
  --clr-accent--200: #088ff7;
  /* accent--300 */
  --clr-accent--300: #0673c6;
  /* accent--400 */
  --clr-accent--400: #055694;
  /* accent--500 */
  --clr-accent--500: #033963;
  /* accent--dim-85-percent */
  --clr-accent--dim-85-percent: rgba(3, 57, 99, 0.85);
  /* accent--dim-25-percent */
  --clr-accent--dim-25-percent: rgba(3, 57, 99, 0.25);
  /* accent--source */
  --clr-accent--source: #033963;
  /* divider--100 */
  --clr-divider--100: #72b4ee;
  /* divider--200 */
  --clr-divider--200: #449ce9;
  /* divider--300 */
  --clr-divider--300: #1a84e0;
  /* divider--400 */
  --clr-divider--400: #1569b2;
  /* divider--500 */
  --clr-divider--500: #104e84;
  /* divider--blue-dim-20-percent */
  --clr-divider--blue-dim-20-percent: rgba(16, 79, 134, 0.2);
  /* divider--source-blue */
  --clr-divider--source-blue: #104F86;
  /* injection--100 */
  --clr-injection--100: #efe8c3;
  /* injection--200 */
  --clr-injection--200: #efe8c3;
  /* injection--300 */
  --clr-injection--300: #e4d89a;
  /* injection--400 */
  --clr-injection--400: #dac872;
  /* injection--500 */
  --clr-injection--500: #cfb94a;
  /* injection--dim-85-percent */
  --clr-injection--dim-85-percent: rgba(207, 185, 74, 0.85);
  /* injection--source */
  --clr-injection--source: #CFBA4B;
  /* whitish--100 */
  --clr-whitish--100: white;
  /* whitish--200 */
  --clr-whitish--200: #e6e6e6;
  /* whitish--300 */
  --clr-whitish--300: #cccccc;
  /* whitish--400 */
  --clr-whitish--400: #b3b3b3;
  /* whitish--500 */
  --clr-whitish--500: #999999;
  /* whitish--dim-40-precent */
  --clr-whitish--dim-40-precent: rgba(255, 255, 255, 0.4);
  /* whitish--source */
  --clr-whitish--source: #ffffff;
  /* blackish--100 */
  --clr-blackish--100: #c4c4c4;
  /* blackish--200 */
  --clr-blackish--200: #333333;
  /* blackish--300 */
  --clr-blackish--300: #460a06;
  /* blackish--dim-300--10-percent */
  --clr-blackish--dim-300--10-percent: rgba(70, 10, 6, 0.1);
  /* blackish--400 */
  --clr-blackish--400: #1a1a1a;
  /* blackish--500 */
  --clr-blackish--500: black;
  /* blackish--dim-70-percent */
  --clr-blackish--dim-70-percent: rgba(0, 0, 0, 0.7);
  /* blackish--vr-one */
  --clr-blackish--vr-one: #212121;
  /* blackish--vr-two */
  --clr-blackish--vr-two: #707070;
  /* blackish--vr-three */
  --clr-blackish--vr-three: #CFD0D7;
  /* blackish--vr-three-dim-90-percent */
  --clr-blackish--vr-three-dim-90-percent: rgba(207, 208, 215, 0.9);
  /* blackish--vr-two-dim-25-percent */
  --clr-blackish--vr-two-dim-25-percent: rgba(112, 112, 112, 0.25);
  /* blackish--source */
  --clr-blackish--source: #000000;
  /* text--100 */
  --clr-text--100: #999999;
  /* text--200 */
  --clr-text--200: gray;
  /* text--300 */
  --clr-text--300: #666666;
  /* text--400 */
  --clr-text--400: #4d4d4d;
  /* text--500 */
  --clr-text--500: #333333;
  /* text--source */
  --clr-text--source: #333333;
  /* shadow--dim-25-percent */
  --clr-shadow--dim-25-percent: rgba(120, 120, 120, 0.25);
  /* shadow--source-1 */
  --clr-shadow--source-1: #797979;
  /* border--source */
  --clr-border--source: #B7B8C0;
  /* --bx-shadow-header--source-1 */
  --bx-shadow-header--source-1: 0px 4px 15px rgba(159, 159, 159, 0.25);
  /* border-radius : --1--1px */
  --rounded--1: 1px;
  /* border-radius : --2--2px */
  --rounded--2: 2px;
  /* border-radius : --3--3px */
  --rounded--3: 3px;
  /* border-radius : --4--4px */
  --rounded--4: 4px;
  /* border-radius : --5--5px */
  --rounded--5: 5px;
  /* border-radius : --6--6px */
  --rounded--6: 6px;
  /* border-radius : --7--7px */
  --rounded--7: 7px;
  /* border-radius : --8--8px */
  --rounded--8: 8px;
  /* border-radius : --9--9px */
  --rounded--9: 9px;
  /* border-radius : --10--10px */
  --rounded--10: 10px;
  /* border-radius : --11--11px */
  --rounded--11: 11px;
  /* border-radius : --12--12px */
  --rounded--12: 12px;
  /* border-radius : --13--13px */
  --rounded--13: 13px;
  /* border-radius : --14--14px */
  --rounded--14: 14px;
  /* border-radius : --15--15px */
  --rounded--15: 15px;
  /* border-radius : --16--16px */
  --rounded--16: 16px;
  /* border-radius : --17--17px */
  --rounded--17: 17px;
  /* border-radius : --18--18px */
  --rounded--18: 18px;
  /* border-radius : --19--19px */
  --rounded--19: 19px;
  /* border-radius : --20--20px */
  --rounded--20: 20px;
  /* border-radius : --21--21px */
  --rounded--21: 21px;
  /* border-radius : --22--22px */
  --rounded--22: 22px;
  /* border-radius : --23--23px */
  --rounded--23: 23px;
  /* border-radius : --24--24px */
  --rounded--24: 24px;
  /* border-radius : --25--25px */
  --rounded--25: 25px;
  /* border-radius : --26--26px */
  --rounded--26: 26px;
  /* border-radius : --27--27px */
  --rounded--27: 27px;
  /* border-radius : --28--28px */
  --rounded--28: 28px;
  /* border-radius : --29--29px */
  --rounded--29: 29px;
  /* border-radius : --30--30px */
  --rounded--30: 30px;
  /* border-radius : --31--31px */
  --rounded--31: 31px;
  /* border-radius : --32--32px */
  --rounded--32: 32px;
  /* border-radius : --33--33px */
  --rounded--33: 33px;
  /* border-radius : --34--34px */
  --rounded--34: 34px;
  /* border-radius : --35--35px */
  --rounded--35: 35px;
  /* border-radius : --36--36px */
  --rounded--36: 36px;
  /* border-radius : --37--37px */
  --rounded--37: 37px;
  /* border-radius : --38--38px */
  --rounded--38: 38px;
  /* border-radius : --39--39px */
  --rounded--39: 39px;
  /* border-radius : --40--40px */
  --rounded--40: 40px;
  /* border-radius : --41--41px */
  --rounded--41: 41px;
  /* border-radius : --42--42px */
  --rounded--42: 42px;
  /* border-radius : --43--43px */
  --rounded--43: 43px;
  /* border-radius : --44--44px */
  --rounded--44: 44px;
  /* border-radius : --45--45px */
  --rounded--45: 45px;
  /* border-radius : --46--46px */
  --rounded--46: 46px;
  /* border-radius : --47--47px */
  --rounded--47: 47px;
  /* border-radius : --48--48px */
  --rounded--48: 48px;
  /* border-radius : --49--49px */
  --rounded--49: 49px;
  /* border-radius : --50--50px */
  --rounded--50: 50px;
  /* family--montserrat */
  --font-family--montserrat: Montserrat, sans-serif;
  /* family--mulish */
  --font-family--mulish: Mulish, sans-serif;
  /* family--sans-serif */
  --font-family--sans-serif: sans-serif;
  /* family--serif */
  --font-family--serif: serif;
  /* size--xs */
  --font-size--xs: 15px;
  /* size--sm */
  --font-size--sm: 16px;
  /* size--md */
  --font-size--md: 17px;
  /* size--lg */
  --font-size--lg: 19px;
  /* size--20 */
  --font-size--20: 20px;
  /* size--22 */
  --font-size--22: 22px;
  /* size--26 */
  --font-size--26: 26px;
  /* size--27 */
  --font-size--27: 27px;
  /* size--29 */
  --font-size--29: 29px;
  /* size--xl */
  --font-size--xl: 30px;
  /* size--xxl */
  --font-size--xxl: 40px;
  /* size--icon-ex */
  --font-size--icon-ex: 60px;
  /* size--icon-extreme */
  --font-size--icon-extreme: 70px;
  /* size--base */
  --font-size--base: 16px;
  /* weight--light */
  --font-weight--light: 300;
  /* weight--regular */
  --font-weight--regular: 400;
  /* weight--medium */
  --font-weight--medium: 500;
  /* weight--semi-bold */
  --font-weight--semi-bold: 600;
  /* weight--bold */
  --font-weight--bold: 700;
  /* weight--bold-ex */
  --font-weight--bold-ex: 800;
  /* style--italic */
  --font-style--italic: italic;
  /* style--oblique */
  --font-style--oblique: oblique;
  /* style--normal */
  --font-style--normal: normal;
  /* style--initial */
  --font-style--initial: initial;
  /* style--inherit */
  --font-style--inherit: inherit;
  /* variant--normal */
  --font-variant--normal: normal;
  /* variant--small-caps */
  --font-variant--small-caps: small-caps;
  /* variant--initial */
  --font-variant--initial: initial;
  /* variant--inherit */
  --font-variant--inherit: inherit;
  /* stretch--u-con */
  --font-stretch--u-con: ultra-condensed;
  /* stretch--e-con */
  --font-stretch--e-con: extra-condensed;
  /* stretch--s-con */
  --font-stretch--s-con: semi-condensed;
  /* stretch--con */
  --font-stretch--con: condensed;
  /* stretch--u-exp */
  --font-stretch--u-exp: ultra-expanded;
  /* stretch--e-exp */
  --font-stretch--e-exp: extra-expanded;
  /* stretch--s-exp */
  --font-stretch--s-exp: semi-expanded;
  /* stretch--exp */
  --font-stretch--exp: expanded;
  /* stretch--normal */
  --font-stretch--normal: normal;
  /* stretch--initial */
  --font-stretch--initial: initial;
  /* stretch--inherit */
  --font-stretch--inherit: inherit;
  /* display--block */
  --d-block: block;
  /* display--i-block */
  --d-i-block: inline-block;
  /* display--inline */
  --d-inline: inline;
  /* display--table */
  --d-table: table;
  /* display--table-c */
  --d-table-c: table-cell;
  /* display--flex */
  --d-flex: flex;
  /* display--i-flex */
  --d-i-flex: inline-flex;
  /* display--grid */
  --d-grid: grid;
  --col-1--of-12: 8.3333333333%;
  --col-2--of-12: 16.6666666667%;
  --col-3--of-12: 25%;
  --col-4--of-12: 33.3333333333%;
  --col-5--of-12: 41.6666666667%;
  --col-6--of-12: 50%;
  --col-7--of-12: 58.3333333333%;
  --col-8--of-12: 66.6666666667%;
  --col-9--of-12: 75%;
  --col-10--of-12: 83.3333333333%;
  --col-11--of-12: 91.6666666667%;
  --col-12--of-12: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-size: var(--font-size--base)px;
}

body {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  color: #333333;
  color: var(--clr-text--source);
  font-family: Montserrat, sans-serif;
  font-family: var(--font-family--montserrat);
  font-size: 1rem;
}
@media only screen and (min-width: 1024px) {
  body {
    font-size: 1rem;
  }
}

.nobel-btn--bg-primary--100 {
  background-color: var(--clr-primary--100);
}

.nobel-btn--text-primary--100 {
  color: var(--clr-primary--100);
}

.nobel-btn--outline-primary--100 {
  background-color: transparent !important;
  border-color: var(--clr-primary--100);
  color: var(--clr-primary--100);
}
.nobel-btn--outline-primary--100:hover, .nobel-btn--outline-primary--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--200 {
  background-color: var(--clr-primary--200);
}

.nobel-btn--text-primary--200 {
  color: var(--clr-primary--200);
}

.nobel-btn--outline-primary--200 {
  background-color: transparent !important;
  border-color: var(--clr-primary--200);
  color: var(--clr-primary--200);
}
.nobel-btn--outline-primary--200:hover, .nobel-btn--outline-primary--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--300 {
  background-color: var(--clr-primary--300);
}

.nobel-btn--text-primary--300 {
  color: var(--clr-primary--300);
}

.nobel-btn--outline-primary--300 {
  background-color: transparent !important;
  border-color: var(--clr-primary--300);
  color: var(--clr-primary--300);
}
.nobel-btn--outline-primary--300:hover, .nobel-btn--outline-primary--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--400 {
  background-color: var(--clr-primary--400);
}

.nobel-btn--text-primary--400 {
  color: var(--clr-primary--400);
}

.nobel-btn--outline-primary--400 {
  background-color: transparent !important;
  border-color: var(--clr-primary--400);
  color: var(--clr-primary--400);
}
.nobel-btn--outline-primary--400:hover, .nobel-btn--outline-primary--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--500 {
  background-color: var(--clr-primary--500);
}

.nobel-btn--text-primary--500 {
  color: var(--clr-primary--500);
}

.nobel-btn--outline-primary--500 {
  background-color: transparent !important;
  border-color: var(--clr-primary--500);
  color: var(--clr-primary--500);
}
.nobel-btn--outline-primary--500:hover, .nobel-btn--outline-primary--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--dim-55-percent {
  background-color: rgba(105, 7, 2, 0.55);
  background-color: var(--clr-primary--dim-55-percent);
}

.nobel-btn--text-primary--dim-55-percent {
  color: rgba(105, 7, 2, 0.55);
  color: var(--clr-primary--dim-55-percent);
}

.nobel-btn--outline-primary--dim-55-percent {
  background-color: transparent !important;
  border-color: rgba(105, 7, 2, 0.55);
  border-color: var(--clr-primary--dim-55-percent);
  color: rgba(105, 7, 2, 0.55);
  color: var(--clr-primary--dim-55-percent);
}
.nobel-btn--outline-primary--dim-55-percent:hover, .nobel-btn--outline-primary--dim-55-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--dim-70-percent {
  background-color: rgba(105, 7, 2, 0.7);
  background-color: var(--clr-primary--dim-70-percent);
}

.nobel-btn--text-primary--dim-70-percent {
  color: rgba(105, 7, 2, 0.7);
  color: var(--clr-primary--dim-70-percent);
}

.nobel-btn--outline-primary--dim-70-percent {
  background-color: transparent !important;
  border-color: rgba(105, 7, 2, 0.7);
  border-color: var(--clr-primary--dim-70-percent);
  color: rgba(105, 7, 2, 0.7);
  color: var(--clr-primary--dim-70-percent);
}
.nobel-btn--outline-primary--dim-70-percent:hover, .nobel-btn--outline-primary--dim-70-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--dim-85-percent {
  background-color: rgba(105, 7, 2, 0.85);
  background-color: var(--clr-primary--dim-85-percent);
}

.nobel-btn--text-primary--dim-85-percent {
  color: rgba(105, 7, 2, 0.85);
  color: var(--clr-primary--dim-85-percent);
}

.nobel-btn--outline-primary--dim-85-percent {
  background-color: transparent !important;
  border-color: rgba(105, 7, 2, 0.85);
  border-color: var(--clr-primary--dim-85-percent);
  color: rgba(105, 7, 2, 0.85);
  color: var(--clr-primary--dim-85-percent);
}
.nobel-btn--outline-primary--dim-85-percent:hover, .nobel-btn--outline-primary--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-primary--source {
  background-color: #690702;
  background-color: var(--clr-primary--source);
}

.nobel-btn--text-primary--source {
  color: #690702;
  color: var(--clr-primary--source);
}

.nobel-btn--outline-primary--source {
  background-color: transparent !important;
  border-color: #690702;
  border-color: var(--clr-primary--source);
  color: #690702;
  color: var(--clr-primary--source);
}
.nobel-btn--outline-primary--source:hover, .nobel-btn--outline-primary--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--100 {
  background-color: var(--clr-accent--100);
}

.nobel-btn--text-accent--100 {
  color: var(--clr-accent--100);
}

.nobel-btn--outline-accent--100 {
  background-color: transparent !important;
  border-color: var(--clr-accent--100);
  color: var(--clr-accent--100);
}
.nobel-btn--outline-accent--100:hover, .nobel-btn--outline-accent--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--200 {
  background-color: var(--clr-accent--200);
}

.nobel-btn--text-accent--200 {
  color: var(--clr-accent--200);
}

.nobel-btn--outline-accent--200 {
  background-color: transparent !important;
  border-color: var(--clr-accent--200);
  color: var(--clr-accent--200);
}
.nobel-btn--outline-accent--200:hover, .nobel-btn--outline-accent--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--300 {
  background-color: var(--clr-accent--300);
}

.nobel-btn--text-accent--300 {
  color: var(--clr-accent--300);
}

.nobel-btn--outline-accent--300 {
  background-color: transparent !important;
  border-color: var(--clr-accent--300);
  color: var(--clr-accent--300);
}
.nobel-btn--outline-accent--300:hover, .nobel-btn--outline-accent--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--400 {
  background-color: var(--clr-accent--400);
}

.nobel-btn--text-accent--400 {
  color: var(--clr-accent--400);
}

.nobel-btn--outline-accent--400 {
  background-color: transparent !important;
  border-color: var(--clr-accent--400);
  color: var(--clr-accent--400);
}
.nobel-btn--outline-accent--400:hover, .nobel-btn--outline-accent--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--500 {
  background-color: var(--clr-accent--500);
}

.nobel-btn--text-accent--500 {
  color: var(--clr-accent--500);
}

.nobel-btn--outline-accent--500 {
  background-color: transparent !important;
  border-color: var(--clr-accent--500);
  color: var(--clr-accent--500);
}
.nobel-btn--outline-accent--500:hover, .nobel-btn--outline-accent--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--dim-85-percent {
  background-color: rgba(3, 57, 99, 0.85);
  background-color: var(--clr-accent--dim-85-percent);
}

.nobel-btn--text-accent--dim-85-percent {
  color: rgba(3, 57, 99, 0.85);
  color: var(--clr-accent--dim-85-percent);
}

.nobel-btn--outline-accent--dim-85-percent {
  background-color: transparent !important;
  border-color: rgba(3, 57, 99, 0.85);
  border-color: var(--clr-accent--dim-85-percent);
  color: rgba(3, 57, 99, 0.85);
  color: var(--clr-accent--dim-85-percent);
}
.nobel-btn--outline-accent--dim-85-percent:hover, .nobel-btn--outline-accent--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--dim-25-percent {
  background-color: rgba(3, 57, 99, 0.25);
  background-color: var(--clr-accent--dim-25-percent);
}

.nobel-btn--text-accent--dim-25-percent {
  color: rgba(3, 57, 99, 0.25);
  color: var(--clr-accent--dim-25-percent);
}

.nobel-btn--outline-accent--dim-25-percent {
  background-color: transparent !important;
  border-color: rgba(3, 57, 99, 0.25);
  border-color: var(--clr-accent--dim-25-percent);
  color: rgba(3, 57, 99, 0.25);
  color: var(--clr-accent--dim-25-percent);
}
.nobel-btn--outline-accent--dim-25-percent:hover, .nobel-btn--outline-accent--dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-accent--source {
  background-color: #033963;
  background-color: var(--clr-accent--source);
}

.nobel-btn--text-accent--source {
  color: #033963;
  color: var(--clr-accent--source);
}

.nobel-btn--outline-accent--source {
  background-color: transparent !important;
  border-color: #033963;
  border-color: var(--clr-accent--source);
  color: #033963;
  color: var(--clr-accent--source);
}
.nobel-btn--outline-accent--source:hover, .nobel-btn--outline-accent--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--100 {
  background-color: var(--clr-divider--100);
}

.nobel-btn--text-divider--100 {
  color: var(--clr-divider--100);
}

.nobel-btn--outline-divider--100 {
  background-color: transparent !important;
  border-color: var(--clr-divider--100);
  color: var(--clr-divider--100);
}
.nobel-btn--outline-divider--100:hover, .nobel-btn--outline-divider--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--200 {
  background-color: var(--clr-divider--200);
}

.nobel-btn--text-divider--200 {
  color: var(--clr-divider--200);
}

.nobel-btn--outline-divider--200 {
  background-color: transparent !important;
  border-color: var(--clr-divider--200);
  color: var(--clr-divider--200);
}
.nobel-btn--outline-divider--200:hover, .nobel-btn--outline-divider--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--300 {
  background-color: var(--clr-divider--300);
}

.nobel-btn--text-divider--300 {
  color: var(--clr-divider--300);
}

.nobel-btn--outline-divider--300 {
  background-color: transparent !important;
  border-color: var(--clr-divider--300);
  color: var(--clr-divider--300);
}
.nobel-btn--outline-divider--300:hover, .nobel-btn--outline-divider--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--400 {
  background-color: var(--clr-divider--400);
}

.nobel-btn--text-divider--400 {
  color: var(--clr-divider--400);
}

.nobel-btn--outline-divider--400 {
  background-color: transparent !important;
  border-color: var(--clr-divider--400);
  color: var(--clr-divider--400);
}
.nobel-btn--outline-divider--400:hover, .nobel-btn--outline-divider--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--500 {
  background-color: var(--clr-divider--500);
}

.nobel-btn--text-divider--500 {
  color: var(--clr-divider--500);
}

.nobel-btn--outline-divider--500 {
  background-color: transparent !important;
  border-color: var(--clr-divider--500);
  color: var(--clr-divider--500);
}
.nobel-btn--outline-divider--500:hover, .nobel-btn--outline-divider--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--blue-dim-20-percent {
  background-color: rgba(16, 79, 134, 0.2);
  background-color: var(--clr-divider--blue-dim-20-percent);
}

.nobel-btn--text-divider--blue-dim-20-percent {
  color: rgba(16, 79, 134, 0.2);
  color: var(--clr-divider--blue-dim-20-percent);
}

.nobel-btn--outline-divider--blue-dim-20-percent {
  background-color: transparent !important;
  border-color: rgba(16, 79, 134, 0.2);
  border-color: var(--clr-divider--blue-dim-20-percent);
  color: rgba(16, 79, 134, 0.2);
  color: var(--clr-divider--blue-dim-20-percent);
}
.nobel-btn--outline-divider--blue-dim-20-percent:hover, .nobel-btn--outline-divider--blue-dim-20-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-divider--source-blue {
  background-color: #104F86;
  background-color: var(--clr-divider--source-blue);
}

.nobel-btn--text-divider--source-blue {
  color: #104F86;
  color: var(--clr-divider--source-blue);
}

.nobel-btn--outline-divider--source-blue {
  background-color: transparent !important;
  border-color: #104F86;
  border-color: var(--clr-divider--source-blue);
  color: #104F86;
  color: var(--clr-divider--source-blue);
}
.nobel-btn--outline-divider--source-blue:hover, .nobel-btn--outline-divider--source-blue:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--100 {
  background-color: var(--clr-injection--100);
}

.nobel-btn--text-injection--100 {
  color: var(--clr-injection--100);
}

.nobel-btn--outline-injection--100 {
  background-color: transparent !important;
  border-color: var(--clr-injection--100);
  color: var(--clr-injection--100);
}
.nobel-btn--outline-injection--100:hover, .nobel-btn--outline-injection--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--200 {
  background-color: var(--clr-injection--200);
}

.nobel-btn--text-injection--200 {
  color: var(--clr-injection--200);
}

.nobel-btn--outline-injection--200 {
  background-color: transparent !important;
  border-color: var(--clr-injection--200);
  color: var(--clr-injection--200);
}
.nobel-btn--outline-injection--200:hover, .nobel-btn--outline-injection--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--300 {
  background-color: var(--clr-injection--300);
}

.nobel-btn--text-injection--300 {
  color: var(--clr-injection--300);
}

.nobel-btn--outline-injection--300 {
  background-color: transparent !important;
  border-color: var(--clr-injection--300);
  color: var(--clr-injection--300);
}
.nobel-btn--outline-injection--300:hover, .nobel-btn--outline-injection--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--400 {
  background-color: var(--clr-injection--400);
}

.nobel-btn--text-injection--400 {
  color: var(--clr-injection--400);
}

.nobel-btn--outline-injection--400 {
  background-color: transparent !important;
  border-color: var(--clr-injection--400);
  color: var(--clr-injection--400);
}
.nobel-btn--outline-injection--400:hover, .nobel-btn--outline-injection--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--500 {
  background-color: var(--clr-injection--500);
}

.nobel-btn--text-injection--500 {
  color: var(--clr-injection--500);
}

.nobel-btn--outline-injection--500 {
  background-color: transparent !important;
  border-color: var(--clr-injection--500);
  color: var(--clr-injection--500);
}
.nobel-btn--outline-injection--500:hover, .nobel-btn--outline-injection--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--dim-85-percent {
  background-color: rgba(207, 185, 74, 0.85);
  background-color: var(--clr-injection--dim-85-percent);
}

.nobel-btn--text-injection--dim-85-percent {
  color: rgba(207, 185, 74, 0.85);
  color: var(--clr-injection--dim-85-percent);
}

.nobel-btn--outline-injection--dim-85-percent {
  background-color: transparent !important;
  border-color: rgba(207, 185, 74, 0.85);
  border-color: var(--clr-injection--dim-85-percent);
  color: rgba(207, 185, 74, 0.85);
  color: var(--clr-injection--dim-85-percent);
}
.nobel-btn--outline-injection--dim-85-percent:hover, .nobel-btn--outline-injection--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-injection--source {
  background-color: #CFBA4B;
  background-color: var(--clr-injection--source);
}

.nobel-btn--text-injection--source {
  color: #CFBA4B;
  color: var(--clr-injection--source);
}

.nobel-btn--outline-injection--source {
  background-color: transparent !important;
  border-color: #CFBA4B;
  border-color: var(--clr-injection--source);
  color: #CFBA4B;
  color: var(--clr-injection--source);
}
.nobel-btn--outline-injection--source:hover, .nobel-btn--outline-injection--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--100 {
  background-color: var(--clr-whitish--100);
}

.nobel-btn--text-whitish--100 {
  color: var(--clr-whitish--100);
}

.nobel-btn--outline-whitish--100 {
  background-color: transparent !important;
  border-color: var(--clr-whitish--100);
  color: var(--clr-whitish--100);
}
.nobel-btn--outline-whitish--100:hover, .nobel-btn--outline-whitish--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--200 {
  background-color: var(--clr-whitish--200);
}

.nobel-btn--text-whitish--200 {
  color: var(--clr-whitish--200);
}

.nobel-btn--outline-whitish--200 {
  background-color: transparent !important;
  border-color: var(--clr-whitish--200);
  color: var(--clr-whitish--200);
}
.nobel-btn--outline-whitish--200:hover, .nobel-btn--outline-whitish--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--300 {
  background-color: var(--clr-whitish--300);
}

.nobel-btn--text-whitish--300 {
  color: var(--clr-whitish--300);
}

.nobel-btn--outline-whitish--300 {
  background-color: transparent !important;
  border-color: var(--clr-whitish--300);
  color: var(--clr-whitish--300);
}
.nobel-btn--outline-whitish--300:hover, .nobel-btn--outline-whitish--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--400 {
  background-color: var(--clr-whitish--400);
}

.nobel-btn--text-whitish--400 {
  color: var(--clr-whitish--400);
}

.nobel-btn--outline-whitish--400 {
  background-color: transparent !important;
  border-color: var(--clr-whitish--400);
  color: var(--clr-whitish--400);
}
.nobel-btn--outline-whitish--400:hover, .nobel-btn--outline-whitish--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--500 {
  background-color: var(--clr-whitish--500);
}

.nobel-btn--text-whitish--500 {
  color: var(--clr-whitish--500);
}

.nobel-btn--outline-whitish--500 {
  background-color: transparent !important;
  border-color: var(--clr-whitish--500);
  color: var(--clr-whitish--500);
}
.nobel-btn--outline-whitish--500:hover, .nobel-btn--outline-whitish--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--dim-40-precent {
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--clr-whitish--dim-40-precent);
}

.nobel-btn--text-whitish--dim-40-precent {
  color: rgba(255, 255, 255, 0.4);
  color: var(--clr-whitish--dim-40-precent);
}

.nobel-btn--outline-whitish--dim-40-precent {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.4);
  border-color: var(--clr-whitish--dim-40-precent);
  color: rgba(255, 255, 255, 0.4);
  color: var(--clr-whitish--dim-40-precent);
}
.nobel-btn--outline-whitish--dim-40-precent:hover, .nobel-btn--outline-whitish--dim-40-precent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-whitish--source {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
}

.nobel-btn--text-whitish--source {
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--source {
  background-color: transparent !important;
  border-color: #ffffff;
  border-color: var(--clr-whitish--source);
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-btn--outline-whitish--source:hover, .nobel-btn--outline-whitish--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--100 {
  background-color: var(--clr-blackish--100);
}

.nobel-btn--text-blackish--100 {
  color: var(--clr-blackish--100);
}

.nobel-btn--outline-blackish--100 {
  background-color: transparent !important;
  border-color: var(--clr-blackish--100);
  color: var(--clr-blackish--100);
}
.nobel-btn--outline-blackish--100:hover, .nobel-btn--outline-blackish--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--200 {
  background-color: var(--clr-blackish--200);
}

.nobel-btn--text-blackish--200 {
  color: var(--clr-blackish--200);
}

.nobel-btn--outline-blackish--200 {
  background-color: transparent !important;
  border-color: var(--clr-blackish--200);
  color: var(--clr-blackish--200);
}
.nobel-btn--outline-blackish--200:hover, .nobel-btn--outline-blackish--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--300 {
  background-color: var(--clr-blackish--300);
}

.nobel-btn--text-blackish--300 {
  color: var(--clr-blackish--300);
}

.nobel-btn--outline-blackish--300 {
  background-color: transparent !important;
  border-color: var(--clr-blackish--300);
  color: var(--clr-blackish--300);
}
.nobel-btn--outline-blackish--300:hover, .nobel-btn--outline-blackish--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--dim-300--10-percent {
  background-color: rgba(70, 10, 6, 0.1);
  background-color: var(--clr-blackish--dim-300--10-percent);
}

.nobel-btn--text-blackish--dim-300--10-percent {
  color: rgba(70, 10, 6, 0.1);
  color: var(--clr-blackish--dim-300--10-percent);
}

.nobel-btn--outline-blackish--dim-300--10-percent {
  background-color: transparent !important;
  border-color: rgba(70, 10, 6, 0.1);
  border-color: var(--clr-blackish--dim-300--10-percent);
  color: rgba(70, 10, 6, 0.1);
  color: var(--clr-blackish--dim-300--10-percent);
}
.nobel-btn--outline-blackish--dim-300--10-percent:hover, .nobel-btn--outline-blackish--dim-300--10-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--400 {
  background-color: var(--clr-blackish--400);
}

.nobel-btn--text-blackish--400 {
  color: var(--clr-blackish--400);
}

.nobel-btn--outline-blackish--400 {
  background-color: transparent !important;
  border-color: var(--clr-blackish--400);
  color: var(--clr-blackish--400);
}
.nobel-btn--outline-blackish--400:hover, .nobel-btn--outline-blackish--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--500 {
  background-color: var(--clr-blackish--500);
}

.nobel-btn--text-blackish--500 {
  color: var(--clr-blackish--500);
}

.nobel-btn--outline-blackish--500 {
  background-color: transparent !important;
  border-color: var(--clr-blackish--500);
  color: var(--clr-blackish--500);
}
.nobel-btn--outline-blackish--500:hover, .nobel-btn--outline-blackish--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--dim-70-percent {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--clr-blackish--dim-70-percent);
}

.nobel-btn--text-blackish--dim-70-percent {
  color: rgba(0, 0, 0, 0.7);
  color: var(--clr-blackish--dim-70-percent);
}

.nobel-btn--outline-blackish--dim-70-percent {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.7);
  border-color: var(--clr-blackish--dim-70-percent);
  color: rgba(0, 0, 0, 0.7);
  color: var(--clr-blackish--dim-70-percent);
}
.nobel-btn--outline-blackish--dim-70-percent:hover, .nobel-btn--outline-blackish--dim-70-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--vr-one {
  background-color: #212121;
  background-color: var(--clr-blackish--vr-one);
}

.nobel-btn--text-blackish--vr-one {
  color: #212121;
  color: var(--clr-blackish--vr-one);
}

.nobel-btn--outline-blackish--vr-one {
  background-color: transparent !important;
  border-color: #212121;
  border-color: var(--clr-blackish--vr-one);
  color: #212121;
  color: var(--clr-blackish--vr-one);
}
.nobel-btn--outline-blackish--vr-one:hover, .nobel-btn--outline-blackish--vr-one:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--vr-two {
  background-color: #707070;
  background-color: var(--clr-blackish--vr-two);
}

.nobel-btn--text-blackish--vr-two {
  color: #707070;
  color: var(--clr-blackish--vr-two);
}

.nobel-btn--outline-blackish--vr-two {
  background-color: transparent !important;
  border-color: #707070;
  border-color: var(--clr-blackish--vr-two);
  color: #707070;
  color: var(--clr-blackish--vr-two);
}
.nobel-btn--outline-blackish--vr-two:hover, .nobel-btn--outline-blackish--vr-two:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--vr-three {
  background-color: #CFD0D7;
  background-color: var(--clr-blackish--vr-three);
}

.nobel-btn--text-blackish--vr-three {
  color: #CFD0D7;
  color: var(--clr-blackish--vr-three);
}

.nobel-btn--outline-blackish--vr-three {
  background-color: transparent !important;
  border-color: #CFD0D7;
  border-color: var(--clr-blackish--vr-three);
  color: #CFD0D7;
  color: var(--clr-blackish--vr-three);
}
.nobel-btn--outline-blackish--vr-three:hover, .nobel-btn--outline-blackish--vr-three:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--vr-three-dim-90-percent {
  background-color: rgba(207, 208, 215, 0.9);
  background-color: var(--clr-blackish--vr-three-dim-90-percent);
}

.nobel-btn--text-blackish--vr-three-dim-90-percent {
  color: rgba(207, 208, 215, 0.9);
  color: var(--clr-blackish--vr-three-dim-90-percent);
}

.nobel-btn--outline-blackish--vr-three-dim-90-percent {
  background-color: transparent !important;
  border-color: rgba(207, 208, 215, 0.9);
  border-color: var(--clr-blackish--vr-three-dim-90-percent);
  color: rgba(207, 208, 215, 0.9);
  color: var(--clr-blackish--vr-three-dim-90-percent);
}
.nobel-btn--outline-blackish--vr-three-dim-90-percent:hover, .nobel-btn--outline-blackish--vr-three-dim-90-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--vr-two-dim-25-percent {
  background-color: rgba(112, 112, 112, 0.25);
  background-color: var(--clr-blackish--vr-two-dim-25-percent);
}

.nobel-btn--text-blackish--vr-two-dim-25-percent {
  color: rgba(112, 112, 112, 0.25);
  color: var(--clr-blackish--vr-two-dim-25-percent);
}

.nobel-btn--outline-blackish--vr-two-dim-25-percent {
  background-color: transparent !important;
  border-color: rgba(112, 112, 112, 0.25);
  border-color: var(--clr-blackish--vr-two-dim-25-percent);
  color: rgba(112, 112, 112, 0.25);
  color: var(--clr-blackish--vr-two-dim-25-percent);
}
.nobel-btn--outline-blackish--vr-two-dim-25-percent:hover, .nobel-btn--outline-blackish--vr-two-dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-blackish--source {
  background-color: #000000;
  background-color: var(--clr-blackish--source);
}

.nobel-btn--text-blackish--source {
  color: #000000;
  color: var(--clr-blackish--source);
}

.nobel-btn--outline-blackish--source {
  background-color: transparent !important;
  border-color: #000000;
  border-color: var(--clr-blackish--source);
  color: #000000;
  color: var(--clr-blackish--source);
}
.nobel-btn--outline-blackish--source:hover, .nobel-btn--outline-blackish--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--100 {
  background-color: var(--clr-text--100);
}

.nobel-btn--text-text--100 {
  color: var(--clr-text--100);
}

.nobel-btn--outline-text--100 {
  background-color: transparent !important;
  border-color: var(--clr-text--100);
  color: var(--clr-text--100);
}
.nobel-btn--outline-text--100:hover, .nobel-btn--outline-text--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--200 {
  background-color: var(--clr-text--200);
}

.nobel-btn--text-text--200 {
  color: var(--clr-text--200);
}

.nobel-btn--outline-text--200 {
  background-color: transparent !important;
  border-color: var(--clr-text--200);
  color: var(--clr-text--200);
}
.nobel-btn--outline-text--200:hover, .nobel-btn--outline-text--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--300 {
  background-color: var(--clr-text--300);
}

.nobel-btn--text-text--300 {
  color: var(--clr-text--300);
}

.nobel-btn--outline-text--300 {
  background-color: transparent !important;
  border-color: var(--clr-text--300);
  color: var(--clr-text--300);
}
.nobel-btn--outline-text--300:hover, .nobel-btn--outline-text--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--400 {
  background-color: var(--clr-text--400);
}

.nobel-btn--text-text--400 {
  color: var(--clr-text--400);
}

.nobel-btn--outline-text--400 {
  background-color: transparent !important;
  border-color: var(--clr-text--400);
  color: var(--clr-text--400);
}
.nobel-btn--outline-text--400:hover, .nobel-btn--outline-text--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--500 {
  background-color: var(--clr-text--500);
}

.nobel-btn--text-text--500 {
  color: var(--clr-text--500);
}

.nobel-btn--outline-text--500 {
  background-color: transparent !important;
  border-color: var(--clr-text--500);
  color: var(--clr-text--500);
}
.nobel-btn--outline-text--500:hover, .nobel-btn--outline-text--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-text--source {
  background-color: #333333;
  background-color: var(--clr-text--source);
}

.nobel-btn--text-text--source {
  color: #333333;
  color: var(--clr-text--source);
}

.nobel-btn--outline-text--source {
  background-color: transparent !important;
  border-color: #333333;
  border-color: var(--clr-text--source);
  color: #333333;
  color: var(--clr-text--source);
}
.nobel-btn--outline-text--source:hover, .nobel-btn--outline-text--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-shadow--dim-25-percent {
  background-color: rgba(120, 120, 120, 0.25);
  background-color: var(--clr-shadow--dim-25-percent);
}

.nobel-btn--text-shadow--dim-25-percent {
  color: rgba(120, 120, 120, 0.25);
  color: var(--clr-shadow--dim-25-percent);
}

.nobel-btn--outline-shadow--dim-25-percent {
  background-color: transparent !important;
  border-color: rgba(120, 120, 120, 0.25);
  border-color: var(--clr-shadow--dim-25-percent);
  color: rgba(120, 120, 120, 0.25);
  color: var(--clr-shadow--dim-25-percent);
}
.nobel-btn--outline-shadow--dim-25-percent:hover, .nobel-btn--outline-shadow--dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-shadow--source-1 {
  background-color: #797979;
  background-color: var(--clr-shadow--source-1);
}

.nobel-btn--text-shadow--source-1 {
  color: #797979;
  color: var(--clr-shadow--source-1);
}

.nobel-btn--outline-shadow--source-1 {
  background-color: transparent !important;
  border-color: #797979;
  border-color: var(--clr-shadow--source-1);
  color: #797979;
  color: var(--clr-shadow--source-1);
}
.nobel-btn--outline-shadow--source-1:hover, .nobel-btn--outline-shadow--source-1:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--bg-border--source {
  background-color: #B7B8C0;
  background-color: var(--clr-border--source);
}

.nobel-btn--text-border--source {
  color: #B7B8C0;
  color: var(--clr-border--source);
}

.nobel-btn--outline-border--source {
  background-color: transparent !important;
  border-color: #B7B8C0;
  border-color: var(--clr-border--source);
  color: #B7B8C0;
  color: var(--clr-border--source);
}
.nobel-btn--outline-border--source:hover, .nobel-btn--outline-border--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn {
  display: inline-block;
  padding: 10px 21px;
  border: 1px solid transparent;
  text-align: center;
  transition: all 300ms ease-in-out 0s;
}
@media only screen and (min-width: 920px) {
  .nobel-btn--md {
    min-width: 200px;
    padding: 12px 21px;
  }
}
.nobel-btn--outline {
  background-color: transparent;
}
.nobel-btn:hover, .nobel-btn:focus {
  transition: all 300ms ease-in-out 0s;
  background-color: #690702;
  background-color: var(--clr-primary--500);
  text-decoration: none;
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-btn--bg-primary--source:hover, .nobel-btn--bg-primary--source:focus {
  background-color: #033963;
  background-color: var(--clr-accent--source);
}
.nobel-btn--bg-primary--source {
  background-color: #690702 !important;
  background-color: var(--clr-primary--source) !important;
}
.nobel-btn--bg-primary--source:hover, .nobel-btn--bg-primary--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
}
.nobel-btn--outline-primary--source {
  background-color: transparent !important;
}
.nobel-btn--outline-primary--source:hover, .nobel-btn--outline-primary--source:focus {
  background-color: #690702 !important;
  background-color: var(--clr-prinary--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-btn--bg-whitish--source:hover, .nobel-btn--bg-whitish--source:focus {
  background-color: #690702;
  background-color: var(--clr-primary--source);
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-btn--outline {
  background-color: transparent;
}
.nobel-btn--outline.nobel-btn--bg-whitish--source {
  border-color: #ffffff;
  border-color: var(--clr-whitish--source);
}
.nobel-btn--outline.nobel-btn--bg-whitish--source:hover, .nobel-btn--outline.nobel-btn--bg-whitish--source:focus {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  color: #690702;
  color: var(--clr-primary--source);
}

.nobel-btn--outline-primary--100 {
  border-color: var(--clr-primary--100);
  color: var(--clr-primary--100);
}
.nobel-btn--outline-primary--100:hover, .nobel-btn--outline-primary--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--200 {
  border-color: var(--clr-primary--200);
  color: var(--clr-primary--200);
}
.nobel-btn--outline-primary--200:hover, .nobel-btn--outline-primary--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--300 {
  border-color: var(--clr-primary--300);
  color: var(--clr-primary--300);
}
.nobel-btn--outline-primary--300:hover, .nobel-btn--outline-primary--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--400 {
  border-color: var(--clr-primary--400);
  color: var(--clr-primary--400);
}
.nobel-btn--outline-primary--400:hover, .nobel-btn--outline-primary--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--500 {
  border-color: var(--clr-primary--500);
  color: var(--clr-primary--500);
}
.nobel-btn--outline-primary--500:hover, .nobel-btn--outline-primary--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--dim-55-percent {
  border-color: rgba(105, 7, 2, 0.55);
  border-color: var(--clr-primary--dim-55-percent);
  color: rgba(105, 7, 2, 0.55);
  color: var(--clr-primary--dim-55-percent);
}
.nobel-btn--outline-primary--dim-55-percent:hover, .nobel-btn--outline-primary--dim-55-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--dim-70-percent {
  border-color: rgba(105, 7, 2, 0.7);
  border-color: var(--clr-primary--dim-70-percent);
  color: rgba(105, 7, 2, 0.7);
  color: var(--clr-primary--dim-70-percent);
}
.nobel-btn--outline-primary--dim-70-percent:hover, .nobel-btn--outline-primary--dim-70-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--dim-85-percent {
  border-color: rgba(105, 7, 2, 0.85);
  border-color: var(--clr-primary--dim-85-percent);
  color: rgba(105, 7, 2, 0.85);
  color: var(--clr-primary--dim-85-percent);
}
.nobel-btn--outline-primary--dim-85-percent:hover, .nobel-btn--outline-primary--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-primary--source {
  border-color: #690702;
  border-color: var(--clr-primary--source);
  color: #690702;
  color: var(--clr-primary--source);
}
.nobel-btn--outline-primary--source:hover, .nobel-btn--outline-primary--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--100 {
  border-color: var(--clr-accent--100);
  color: var(--clr-accent--100);
}
.nobel-btn--outline-accent--100:hover, .nobel-btn--outline-accent--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--200 {
  border-color: var(--clr-accent--200);
  color: var(--clr-accent--200);
}
.nobel-btn--outline-accent--200:hover, .nobel-btn--outline-accent--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--300 {
  border-color: var(--clr-accent--300);
  color: var(--clr-accent--300);
}
.nobel-btn--outline-accent--300:hover, .nobel-btn--outline-accent--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--400 {
  border-color: var(--clr-accent--400);
  color: var(--clr-accent--400);
}
.nobel-btn--outline-accent--400:hover, .nobel-btn--outline-accent--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--500 {
  border-color: var(--clr-accent--500);
  color: var(--clr-accent--500);
}
.nobel-btn--outline-accent--500:hover, .nobel-btn--outline-accent--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--dim-85-percent {
  border-color: rgba(3, 57, 99, 0.85);
  border-color: var(--clr-accent--dim-85-percent);
  color: rgba(3, 57, 99, 0.85);
  color: var(--clr-accent--dim-85-percent);
}
.nobel-btn--outline-accent--dim-85-percent:hover, .nobel-btn--outline-accent--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--dim-25-percent {
  border-color: rgba(3, 57, 99, 0.25);
  border-color: var(--clr-accent--dim-25-percent);
  color: rgba(3, 57, 99, 0.25);
  color: var(--clr-accent--dim-25-percent);
}
.nobel-btn--outline-accent--dim-25-percent:hover, .nobel-btn--outline-accent--dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-accent--source {
  border-color: #033963;
  border-color: var(--clr-accent--source);
  color: #033963;
  color: var(--clr-accent--source);
}
.nobel-btn--outline-accent--source:hover, .nobel-btn--outline-accent--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--100 {
  border-color: var(--clr-divider--100);
  color: var(--clr-divider--100);
}
.nobel-btn--outline-divider--100:hover, .nobel-btn--outline-divider--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--200 {
  border-color: var(--clr-divider--200);
  color: var(--clr-divider--200);
}
.nobel-btn--outline-divider--200:hover, .nobel-btn--outline-divider--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--300 {
  border-color: var(--clr-divider--300);
  color: var(--clr-divider--300);
}
.nobel-btn--outline-divider--300:hover, .nobel-btn--outline-divider--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--400 {
  border-color: var(--clr-divider--400);
  color: var(--clr-divider--400);
}
.nobel-btn--outline-divider--400:hover, .nobel-btn--outline-divider--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--500 {
  border-color: var(--clr-divider--500);
  color: var(--clr-divider--500);
}
.nobel-btn--outline-divider--500:hover, .nobel-btn--outline-divider--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--blue-dim-20-percent {
  border-color: rgba(16, 79, 134, 0.2);
  border-color: var(--clr-divider--blue-dim-20-percent);
  color: rgba(16, 79, 134, 0.2);
  color: var(--clr-divider--blue-dim-20-percent);
}
.nobel-btn--outline-divider--blue-dim-20-percent:hover, .nobel-btn--outline-divider--blue-dim-20-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-divider--source-blue {
  border-color: #104F86;
  border-color: var(--clr-divider--source-blue);
  color: #104F86;
  color: var(--clr-divider--source-blue);
}
.nobel-btn--outline-divider--source-blue:hover, .nobel-btn--outline-divider--source-blue:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--100 {
  border-color: var(--clr-injection--100);
  color: var(--clr-injection--100);
}
.nobel-btn--outline-injection--100:hover, .nobel-btn--outline-injection--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--200 {
  border-color: var(--clr-injection--200);
  color: var(--clr-injection--200);
}
.nobel-btn--outline-injection--200:hover, .nobel-btn--outline-injection--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--300 {
  border-color: var(--clr-injection--300);
  color: var(--clr-injection--300);
}
.nobel-btn--outline-injection--300:hover, .nobel-btn--outline-injection--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--400 {
  border-color: var(--clr-injection--400);
  color: var(--clr-injection--400);
}
.nobel-btn--outline-injection--400:hover, .nobel-btn--outline-injection--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--500 {
  border-color: var(--clr-injection--500);
  color: var(--clr-injection--500);
}
.nobel-btn--outline-injection--500:hover, .nobel-btn--outline-injection--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--dim-85-percent {
  border-color: rgba(207, 185, 74, 0.85);
  border-color: var(--clr-injection--dim-85-percent);
  color: rgba(207, 185, 74, 0.85);
  color: var(--clr-injection--dim-85-percent);
}
.nobel-btn--outline-injection--dim-85-percent:hover, .nobel-btn--outline-injection--dim-85-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-injection--source {
  border-color: #CFBA4B;
  border-color: var(--clr-injection--source);
  color: #CFBA4B;
  color: var(--clr-injection--source);
}
.nobel-btn--outline-injection--source:hover, .nobel-btn--outline-injection--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--100 {
  border-color: var(--clr-whitish--100);
  color: var(--clr-whitish--100);
}
.nobel-btn--outline-whitish--100:hover, .nobel-btn--outline-whitish--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--200 {
  border-color: var(--clr-whitish--200);
  color: var(--clr-whitish--200);
}
.nobel-btn--outline-whitish--200:hover, .nobel-btn--outline-whitish--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--300 {
  border-color: var(--clr-whitish--300);
  color: var(--clr-whitish--300);
}
.nobel-btn--outline-whitish--300:hover, .nobel-btn--outline-whitish--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--400 {
  border-color: var(--clr-whitish--400);
  color: var(--clr-whitish--400);
}
.nobel-btn--outline-whitish--400:hover, .nobel-btn--outline-whitish--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--500 {
  border-color: var(--clr-whitish--500);
  color: var(--clr-whitish--500);
}
.nobel-btn--outline-whitish--500:hover, .nobel-btn--outline-whitish--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--dim-40-precent {
  border-color: rgba(255, 255, 255, 0.4);
  border-color: var(--clr-whitish--dim-40-precent);
  color: rgba(255, 255, 255, 0.4);
  color: var(--clr-whitish--dim-40-precent);
}
.nobel-btn--outline-whitish--dim-40-precent:hover, .nobel-btn--outline-whitish--dim-40-precent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-whitish--source {
  border-color: #ffffff;
  border-color: var(--clr-whitish--source);
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-btn--outline-whitish--source:hover, .nobel-btn--outline-whitish--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--100 {
  border-color: var(--clr-blackish--100);
  color: var(--clr-blackish--100);
}
.nobel-btn--outline-blackish--100:hover, .nobel-btn--outline-blackish--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--200 {
  border-color: var(--clr-blackish--200);
  color: var(--clr-blackish--200);
}
.nobel-btn--outline-blackish--200:hover, .nobel-btn--outline-blackish--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--300 {
  border-color: var(--clr-blackish--300);
  color: var(--clr-blackish--300);
}
.nobel-btn--outline-blackish--300:hover, .nobel-btn--outline-blackish--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--dim-300--10-percent {
  border-color: rgba(70, 10, 6, 0.1);
  border-color: var(--clr-blackish--dim-300--10-percent);
  color: rgba(70, 10, 6, 0.1);
  color: var(--clr-blackish--dim-300--10-percent);
}
.nobel-btn--outline-blackish--dim-300--10-percent:hover, .nobel-btn--outline-blackish--dim-300--10-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--400 {
  border-color: var(--clr-blackish--400);
  color: var(--clr-blackish--400);
}
.nobel-btn--outline-blackish--400:hover, .nobel-btn--outline-blackish--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--500 {
  border-color: var(--clr-blackish--500);
  color: var(--clr-blackish--500);
}
.nobel-btn--outline-blackish--500:hover, .nobel-btn--outline-blackish--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--dim-70-percent {
  border-color: rgba(0, 0, 0, 0.7);
  border-color: var(--clr-blackish--dim-70-percent);
  color: rgba(0, 0, 0, 0.7);
  color: var(--clr-blackish--dim-70-percent);
}
.nobel-btn--outline-blackish--dim-70-percent:hover, .nobel-btn--outline-blackish--dim-70-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--vr-one {
  border-color: #212121;
  border-color: var(--clr-blackish--vr-one);
  color: #212121;
  color: var(--clr-blackish--vr-one);
}
.nobel-btn--outline-blackish--vr-one:hover, .nobel-btn--outline-blackish--vr-one:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--vr-two {
  border-color: #707070;
  border-color: var(--clr-blackish--vr-two);
  color: #707070;
  color: var(--clr-blackish--vr-two);
}
.nobel-btn--outline-blackish--vr-two:hover, .nobel-btn--outline-blackish--vr-two:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--vr-three {
  border-color: #CFD0D7;
  border-color: var(--clr-blackish--vr-three);
  color: #CFD0D7;
  color: var(--clr-blackish--vr-three);
}
.nobel-btn--outline-blackish--vr-three:hover, .nobel-btn--outline-blackish--vr-three:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--vr-three-dim-90-percent {
  border-color: rgba(207, 208, 215, 0.9);
  border-color: var(--clr-blackish--vr-three-dim-90-percent);
  color: rgba(207, 208, 215, 0.9);
  color: var(--clr-blackish--vr-three-dim-90-percent);
}
.nobel-btn--outline-blackish--vr-three-dim-90-percent:hover, .nobel-btn--outline-blackish--vr-three-dim-90-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--vr-two-dim-25-percent {
  border-color: rgba(112, 112, 112, 0.25);
  border-color: var(--clr-blackish--vr-two-dim-25-percent);
  color: rgba(112, 112, 112, 0.25);
  color: var(--clr-blackish--vr-two-dim-25-percent);
}
.nobel-btn--outline-blackish--vr-two-dim-25-percent:hover, .nobel-btn--outline-blackish--vr-two-dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-blackish--source {
  border-color: #000000;
  border-color: var(--clr-blackish--source);
  color: #000000;
  color: var(--clr-blackish--source);
}
.nobel-btn--outline-blackish--source:hover, .nobel-btn--outline-blackish--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--100 {
  border-color: var(--clr-text--100);
  color: var(--clr-text--100);
}
.nobel-btn--outline-text--100:hover, .nobel-btn--outline-text--100:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--200 {
  border-color: var(--clr-text--200);
  color: var(--clr-text--200);
}
.nobel-btn--outline-text--200:hover, .nobel-btn--outline-text--200:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--300 {
  border-color: var(--clr-text--300);
  color: var(--clr-text--300);
}
.nobel-btn--outline-text--300:hover, .nobel-btn--outline-text--300:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--400 {
  border-color: var(--clr-text--400);
  color: var(--clr-text--400);
}
.nobel-btn--outline-text--400:hover, .nobel-btn--outline-text--400:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--500 {
  border-color: var(--clr-text--500);
  color: var(--clr-text--500);
}
.nobel-btn--outline-text--500:hover, .nobel-btn--outline-text--500:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-text--source {
  border-color: #333333;
  border-color: var(--clr-text--source);
  color: #333333;
  color: var(--clr-text--source);
}
.nobel-btn--outline-text--source:hover, .nobel-btn--outline-text--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-shadow--dim-25-percent {
  border-color: rgba(120, 120, 120, 0.25);
  border-color: var(--clr-shadow--dim-25-percent);
  color: rgba(120, 120, 120, 0.25);
  color: var(--clr-shadow--dim-25-percent);
}
.nobel-btn--outline-shadow--dim-25-percent:hover, .nobel-btn--outline-shadow--dim-25-percent:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-shadow--source-1 {
  border-color: #797979;
  border-color: var(--clr-shadow--source-1);
  color: #797979;
  color: var(--clr-shadow--source-1);
}
.nobel-btn--outline-shadow--source-1:hover, .nobel-btn--outline-shadow--source-1:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-btn--outline-border--source {
  border-color: #B7B8C0;
  border-color: var(--clr-border--source);
  color: #B7B8C0;
  color: var(--clr-border--source);
}
.nobel-btn--outline-border--source:hover, .nobel-btn--outline-border--source:focus {
  background-color: #033963 !important;
  background-color: var(--clr-accent--source) !important;
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-dropdown {
  position: relative;
}
.nobel-dropdown--menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  min-width: 200px;
  max-width: max-content;
}
.nobel-dropdown--menu a {
  display: block;
  padding: 8px 15px;
}
.nobel-dropdown:hover .nobel-dropdown--menu, .nobel-dropdown:focus .nobel-dropdown--menu {
  visibility: visible;
  opacity: 1;
}

.nobel-divider {
  display: block;
  display: var(--d-block);
  background-color: #104F86;
  background-color: var(--clr-divider--source-blue);
}
.nobel-divider--vertical {
  width: 1px;
  height: 100px;
}
.nobel-divider--horizontal {
  width: 100%;
  height: 1px;
}
.nobel-divider--section {
  margin-bottom: 50px;
}

.nobel-card {
  box-shadow: 0px 4px 15px rgba(120, 120, 120, 0.25);
}
.nobel-card--program {
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(120, 120, 120, 0.25);
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
}
.nobel-card--program .nobel-card__figure {
  max-height: 346px;
  overflow: hidden;
  position: relative;
}
.nobel-card--program .nobel-card__figure img {
  object-fit: cover;
  height: 100%;
  max-height: 346px;
}
.nobel-card--program .nobel-card__figure .nobel-card__title {
  bottom: 20px;
  left: 30px;
  z-index: 2;
  line-height: 1.2;
}
.nobel-card--program .nobel-card__figure:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 10px;
  background-color: #033963;
  background-color: var(--clr-accent--source);
  transition: all 500ms ease-in-out 0s;
}
.nobel-card--program.nobel-card--no-bar .nobel-card__figure {
  position: relative;
}
.nobel-card--program.nobel-card--no-bar .nobel-card__figure:after {
  content: "";
  position: absolute;
  height: 0px;
}
.nobel-card--program:nth-child(2) .nobel-card__figure {
  position: relative;
}
.nobel-card--program:nth-child(2) .nobel-card__figure:after {
  content: "";
  position: absolute;
  background-color: #690702;
  background-color: var(--clr-primary--source);
}
.nobel-card--program:nth-child(5n) .nobel-card__figure {
  position: relative;
}
.nobel-card--program:nth-child(5n) .nobel-card__figure:after {
  content: "";
  position: absolute;
  background-color: #690702;
  background-color: var(--clr-primary--source);
}
.nobel-card--program:nth-child(3) .nobel-card__figure {
  position: relative;
}
.nobel-card--program:nth-child(3) .nobel-card__figure:after {
  content: "";
  position: absolute;
  background-color: #CFBA4B;
  background-color: var(--clr-injection--source);
}
.nobel-card--program:nth-child(6n) .nobel-card__figure {
  position: relative;
}
.nobel-card--program:nth-child(6n) .nobel-card__figure:after {
  content: "";
  position: absolute;
  background-color: #CFBA4B;
  background-color: var(--clr-injection--source);
}
.nobel-card--program .nobel-card__content {
  padding: 30px;
}
.nobel-card--program .nobel-card__content .nobel-card__title {
  font-weight: 600;
  font-weight: var(--font-weight--semi-bold);
  font-size: 20px;
  font-size: var(--font-size--20);
  margin-bottom: 10px;
}
.nobel-card--program:hover .nobel-card__figure, .nobel-card--program:focus .nobel-card__figure {
  position: relative;
}
.nobel-card--program:hover .nobel-card__figure:after, .nobel-card--program:focus .nobel-card__figure:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(105, 7, 2, 0.55);
  background-color: var(--clr-primary--dim-55-percent);
  transition: all 500ms ease-in-out 0s;
}
.nobel-card--program.nobel-card--filter.nobel-filter--show {
  display: block;
}
.nobel-card--program.nobel-card--filter.nobel-filter--hide {
  display: none;
}
.nobel-card--grid {
  display: flex;
  display: var(--d-flex);
  box-shadow: none;
}
.nobel-card--menu-box {
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(120, 120, 120, 0.25);
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  overflow: hidden;
}
.nobel-card--menu-box > a, .nobel-card--menu-box > div {
  padding: 60px 0;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
.nobel-card--menu-box > a:hover, .nobel-card--menu-box > a:focus, .nobel-card--menu-box > div:hover, .nobel-card--menu-box > div:focus {
  background-color: rgba(70, 10, 6, 0.1);
  background-color: var(--clr-blackish--dim-300--10-percent);
}
.nobel-card--menu-box figure {
  margin-bottom: 20px;
}
.nobel-card--menu-box figure svg > path {
  stroke: #000000;
  stroke: var(--clr-blackish--source);
}
.nobel-card--menu-box figure svg > path:hover, .nobel-card--menu-box figure svg > path:focus {
  stroke: #ffffff;
  stroke: var(--clr-whitish--source);
}
.nobel-card--vacancy {
  padding: 0 20px;
}
@media only screen and (min-width: 920px) {
  .nobel-card--vacancy {
    padding: 0 40px;
  }
}
.nobel-card--vacancy.nobel-accordion .nobel-accordion__header, .nobel-card--vacancy.nobel-accordion .nobel-accordion__content {
  padding: 20px 0;
}
@media only screen and (min-width: 920px) {
  .nobel-card--vacancy.nobel-accordion .nobel-accordion__header, .nobel-card--vacancy.nobel-accordion .nobel-accordion__content {
    padding: 40px 0;
  }
}
.nobel-card--vacancy.nobel-accordion .nobel-accordion__header.invisible, .nobel-card--vacancy.nobel-accordion .nobel-accordion__content.invisible {
  padding: 0;
}

.nobel-testimonial article {
  box-shadow: 0px 4px 15px rgba(121, 121, 121, 0.4);
}
@media only screen and (min-width: 920px) {
  .nobel-testimonial article {
    min-height: 355px;
  }
}

.nobel-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  transition: all 500ms ease-in-out 0s;
}
.nobel-modal--is-active {
  overflow: hidden;
}
.nobel-modal--open {
  visibility: visible;
  opacity: 1;
  transition: all 500ms ease-in-out 0s;
}
.nobel-modal--close {
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease-in-out 0s;
}
.nobel-modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.nobel-modal__dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px;
}
.nobel-modal__dialog .nobel-modal__body {
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
}
.nobel-modal__close-btn {
  width: 30px;
  height: 30px;
  background-color: #ffffff !important;
  background-color: var(--clr-whitish--source) !important;
  border-radius: 100%;
  box-shadow: 0px 4px 4px var(--clr-blackish--vr-two-dim-25-percent);
}
.nobel-modal.noble-modal--notification .nobel-modal__close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
}

.nobel-breadcrumb {
  padding-bottom: 30px;
  border-bottom: 1px dashed transparent;
  border-color: #c4c4c4;
  border-color: var(--clr-blackish--100);
}
.nobel-breadcrumb--section {
  padding: 30px 0 0;
}
.nobel-breadcrumb a {
  color: #690702;
  color: var(--clr-primary--source);
}
.nobel-breadcrumb a:hover, .nobel-breadcrumb a:focus {
  color: #4f0703;
  color: var(--clr-primary--400);
}
.nobel-breadcrumb span {
  color: #c4c4c4;
  color: var(--clr-blackish--100);
}
.nobel-breadcrumb li {
  position: relative;
}
.nobel-breadcrumb li + li:before {
  content: "/";
  display: inline-block;
  padding: 0 10px;
  color: #c4c4c4;
  color: var(--clr-blackish--100);
}

.nobel-rich-text__article {
  font-weight: 500;
  font-weight: var(--font-weight--medium);
}
.nobel-rich-text__article strong {
  font-weight: 700;
  font-weight: var(--font-weight--bold);
}
.nobel-rich-text__article > * + * {
  margin-top: 1.3rem;
}
.nobel-rich-text__article h1, .nobel-rich-text__article h2, .nobel-rich-text__article h3, .nobel-rich-text__article h4, .nobel-rich-text__article h5, .nobel-rich-text__article h6 {
  font-weight: 700;
  font-weight: var(--font-weight--bold);
}
.nobel-rich-text__article h1 + *, .nobel-rich-text__article h2 + *, .nobel-rich-text__article h3 + *, .nobel-rich-text__article h4 + *, .nobel-rich-text__article h5 + *, .nobel-rich-text__article h6 + * {
  margin-top: 1rem;
}
.nobel-rich-text__article * + h1, .nobel-rich-text__article * + h2, .nobel-rich-text__article * + h3, .nobel-rich-text__article * + h4, .nobel-rich-text__article * + h5, .nobel-rich-text__article * + h6 {
  margin-top: 1.5rem;
}
.nobel-rich-text__article h1 {
  font-size: 30px;
  font-size: var(--font-size--xl);
}
.nobel-rich-text__article h2 {
  font-size: 30px;
  font-size: var(--font-size--xl);
}
.nobel-rich-text__article h3 {
  font-size: 20px;
  font-size: var(--font-size--20);
}
.nobel-rich-text__article h4 {
  font-size: 19px;
  font-size: var(--font-size--lg);
}
.nobel-rich-text__article h5 {
  font-size: 17px;
  font-size: var(--font-size--md);
}
.nobel-rich-text__article h6 {
  font-size: 16px;
  font-size: var(--font-size--sm);
}
.nobel-rich-text__article ul, .nobel-rich-text__article ol {
  padding-left: 30px;
}
.nobel-rich-text__article ul li + li, .nobel-rich-text__article ol li + li {
  margin-top: 0.5rem;
}
.nobel-rich-text__article ul {
  list-style: disc;
}
.nobel-rich-text__article ol {
  list-style: number;
}
.nobel-rich-text__article img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.nobel-rich-text__article img + * {
  margin-top: 1.5rem;
}
.nobel-rich-text__article * + img {
  margin-top: 1.8rem;
}
.nobel-rich-text__article blockquote {
  padding: 20px 30px;
  border-radius: 15px;
  font-size: 20px;
  font-size: var(--font-size--20);
  font-style: italic;
  font-style: var(--font-style--italic);
  font-weight: 700;
  font-weight: var(--font-weight--bold);
  font-family: Mulish, sans-serif;
  font-family: var(--font-family--mulish);
  box-shadow: 0px 4px 15px rgba(159, 159, 159, 0.25);
  box-shadow: var(--bx-shadow-header--source-1);
  max-width: 900px;
}
.nobel-rich-text__article blockquote + * {
  margin-top: 1.5rem;
}
.nobel-rich-text__article * + blockquote {
  margin-top: 2rem;
}
.nobel-rich-text__feature-image {
  margin-bottom: 2rem;
}

.nobel-accordion__content {
  transition: all 500ms ease-in-out 0s;
}
.nobel-accordion.nobel-accordion--open .nobel-accordion__content {
  transition: all 500ms ease-in-out 0s;
}

.nobel-form__control {
  font-size: 16px;
  font-size: var(--font-size--base);
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  padding: 0 15px;
  outline: none !important;
}
.nobel-form__control:hover, .nobel-form__control:focus {
  border-color: #690702;
  border-color: var(--clr-primary--source);
}
.nobel-form__label {
  display: block;
  display: var(--d-block);
}
.nobel-form__addons {
  padding-left: 50px;
  color: #000000;
  color: var(--clr-blackish--source);
}
.nobel-form__input, .nobel-form__select {
  height: 42px;
  line-height: 42px;
}

/*Bootstrap Calendar*/
.calendar-popup {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  opacity: 0;
  padding: 10px;
  position: absolute;
  top: calc(100% + 20px);
  transition: all 0.1s linear;
  width: 100%;
  opacity: 0;
}
.calendar-popup .month-and-year {
  align-items: center;
  display: flex;
  text-align: center;
  text-transform: uppercase;
}
.calendar-popup .month-and-year h4 {
  width: 100%;
}
.calendar-popup .button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  outline: none;
  position: absolute;
  top: 15px;
}
.calendar-popup .button:hover {
  cursor: pointer;
}
.calendar-popup .button.prev {
  left: 10px;
}
.calendar-popup .button.next {
  right: 10px;
}
.calendar-popup table {
  font-size: 12px;
  width: 100%;
}
.calendar-popup table tr {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 5px 0;
}
.calendar-popup table tr th,
.calendar-popup table tr td {
  text-align: center;
}
.calendar-popup table .day:hover {
  cursor: pointer;
}

.open .calendar-popup {
  opacity: 1;
  position: fixed;
  z-index: 100;
  top: 15%;
  max-width: 300px;
}

.nobel-header {
  box-shadow: 0px 4px 15px rgba(159, 159, 159, 0.25);
  box-shadow: var(--bx-shadow-header--source-1);
}
.nobel-header__navigation__top {
  position: relative;
}
.nobel-header__navigation__top:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  width: 200%;
  height: 100%;
  background-color: #690702;
  background-color: var(--clr-primary--source);
}
@media only screen and (min-width: 768px) {
  .nobel-header__menu > li + li {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .nobel-header__menu > li + li {
    margin-left: 50px;
  }
}
.nobel-header__menu a {
  line-height: 1;
  font-family: Mulish, sans-serif;
  font-family: var(--font-family--mulish);
}
.nobel-header__menu li a > .fa, .nobel-header__menu li a > .mdi {
  display: none;
}
.nobel-header__menu li.nobel-dropdown a > .fa, .nobel-header__menu li.nobel-dropdown a > .mdi {
  display: inline-block;
}

.nobel-footer {
  font-family: Mulish, sans-serif;
  font-family: var(--font-family--mulish);
}
.nobel-footer__top {
  padding: 35px 0;
}
.nobel-footer__brand {
  position: relative;
  top: -60px;
  left: 0;
  height: 161px;
  border-radius: 0 0 20px 20px;
  padding: 20px;
}
.nobel-footer__brand + * {
  position: relative;
  top: -30px;
}
.nobel-footer__menu li + li {
  margin-top: 10px;
}
.nobel-footer__menu a:hover, .nobel-footer__menu a:focus {
  color: #CFBA4B;
  color: var(--clr-injection--source);
}

.nobel-hero--home {
  max-height: 1000px;
}
@media only screen and (min-width: 1024px) {
  .nobel-hero--home {
    height: 1000px;
    padding: 8rem 0 0;
  }
  .nobel-hero--home .nobel-hero__article {
    max-width: 85%;
  }
}
.nobel-hero--home .nobel-btn:hover, .nobel-hero--home .nobel-btn:focus {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  color: #690702;
  color: var(--clr-primary--source);
}
.nobel-hero--home .nobel-clip--inner-top-point {
  z-index: 2;
  position: relative;
}
.nobel-hero--home .nobel-clip--inner-top-point .nobel-card:nth-child(2) {
  padding-top: 40px;
}
.nobel-hero--home .nobel-clip--inner-top-point .nobel-card:nth-child(2) .nobel-icon {
  font-size: 30px;
  font-size: var(--font-size--xl);
}
@media only screen and (min-width: 920px) {
  .nobel-hero--home .nobel-clip--inner-top-point .nobel-card:nth-child(2) {
    padding-top: 100px;
  }
}
@media only screen and (min-width: 1300px) {
  .nobel-hero--home .nobel-clip--inner-top-point .nobel-card:nth-child(2) {
    padding-top: 200px;
  }
}
.nobel-hero--home .nobel-clip--inner-top-point .nobel-card .nobel-btn {
  border: 1px solid transparent;
}
.nobel-hero--home .nobel-clip--inner-top-point .nobel-card .nobel-btn:hover, .nobel-hero--home .nobel-clip--inner-top-point .nobel-card .nobel-btn:focus {
  background-color: #690702;
  background-color: var(--clr-primary--source);
  color: #ffffff;
  color: var(--clr-whitish--source);
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.4);
  border-color: var(--clr-whitish--dim-40-precent);
}
@media only screen and (min-width: 920px) {
  .nobel-hero--home .nobel-clip--inner-top-point .nobel-card {
    padding-bottom: 50px;
  }
}

.nobel-msg {
  padding-bottom: 0 !important;
}

.nobel-news {
  padding-top: 60px !important;
}

@media only screen and (min-width: 920px) {
  .nobel-hero--innerpage .nobel-clip {
    max-width: 50%;
    width: 100%;
  }
  .nobel-hero--innerpage .nobel-clip .nobel-clip {
    display: flex;
    flex-flow: wrap column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 90%;
    height: 100%;
    padding-right: 30px;
  }
  .nobel-hero--innerpage .nobel-clip .nobel-clip > article {
    max-width: 75%;
    margin: 0 auto;
  }
}

.nobel-about .nobel-section__title {
  margin-bottom: 25px;
}
.nobel-about .nobel-section__title + p {
  margin-bottom: 50px;
}

.nobel-program .nobel-section__title {
  display: none;
}
.nobel-program .nobel-header-all {
  display: block;
}
.nobel-program.nobel-program--undergraduate .nobel-header-all {
  display: none;
}
.nobel-program.nobel-program--undergraduate .nobel-header-undergraduate {
  display: block;
}
.nobel-program.nobel-program--postgraduate .nobel-header-all {
  display: none;
}
.nobel-program.nobel-program--postgraduate .nobel-header-postgraduate {
  display: block;
}
.nobel-program.nobel-program--mch .nobel-header-all {
  display: none;
}
.nobel-program.nobel-program--mch .nobel-header-mch {
  display: block;
}

.nobel-affiliation {
  padding-bottom: 30px !important;
}
.nobel-affiliation__logo {
  flex: 1;
  min-width: 25%;
  margin-bottom: 30px;
}
.nobel-affiliation__logo img {
  margin: 0 auto;
}

.nobel-paragraph {
  margin-top: 28px;
}
.nobel-paragraph p + p {
  margin-top: 1.2rem;
}
.nobel-paragraph p + ol, .nobel-paragraph p + ul, .nobel-paragraph ol + p, .nobel-paragraph ul + p {
  margin-top: 1.3rem;
}
.nobel-paragraph li + li {
  margin-top: 1rem;
}
.nobel-paragraph ol + h1, .nobel-paragraph ul + h1,
.nobel-paragraph ol + h2, .nobel-paragraph ul + h2,
.nobel-paragraph ol + h3, .nobel-paragraph ul + h3,
.nobel-paragraph ol + h4, .nobel-paragraph ul + h4,
.nobel-paragraph ol + h5, .nobel-paragraph ul + h5,
.nobel-paragraph ol + h6, .nobel-paragraph ul + h6,
.nobel-paragraph p + h1,
.nobel-paragraph p + h2,
.nobel-paragraph p + h3,
.nobel-paragraph p + h4,
.nobel-paragraph p + h5,
.nobel-paragraph p + h6 {
  margin-top: 2rem;
}
.nobel-paragraph h1, .nobel-paragraph h2, .nobel-paragraph h3, .nobel-paragraph h4, .nobel-paragraph h5, .nobel-paragraph h6 {
  margin-bottom: 0;
}

.nobel-apply-now.nobel-section {
  padding: 40px 0 !important;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1300px) {
  .nobel-apply-now.nobel-section {
    padding: 60px 0 !important;
    margin-bottom: 60px;
  }
}

.nobel-clinical-services--list .nobel-card--menu-box {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
}
.nobel-clinical-services--list .nobel-card--menu-box:hover, .nobel-clinical-services--list .nobel-card--menu-box:focus {
  background-color: #690702;
  background-color: var(--clr-primary--source);
}
.nobel-clinical-services--list .nobel-card--menu-box:hover figure svg > path, .nobel-clinical-services--list .nobel-card--menu-box:focus figure svg > path {
  stroke: #ffffff;
  stroke: var(--clr-whitish--source);
  stroke-opacity: 1;
}
.nobel-clinical-services--list .nobel-card--menu-box:hover figure svg.has-fill > path, .nobel-clinical-services--list .nobel-card--menu-box:focus figure svg.has-fill > path {
  stroke: #ffffff;
  stroke: var(--clr-whitish--source);
  fill: #ffffff;
  fill: var(--clr-whitish--source);
}
.nobel-clinical-services--list .nobel-card--menu-box:hover article > span.nobel-text-accent--source, .nobel-clinical-services--list .nobel-card--menu-box:focus article > span.nobel-text-accent--source {
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-section {
  padding: 40px 0;
  width: 100%;
}
@media only screen and (min-width: 920px) {
  .nobel-section {
    padding: 60px 0;
  }
}
.nobel-section__title {
  line-height: 1.3;
  margin-bottom: 30px;
}
@media only screen and (min-width: 920px) {
  .nobel-section__title {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 920px) {
  .nobel-section__sub-nav__menu {
    background-color: rgba(70, 10, 6, 0.1);
    background-color: var(--clr-blackish--dim-300--10-percent);
    border-radius: 5px;
    overflow: hidden;
  }
  .nobel-section__sub-nav__menu li {
    flex: 1;
  }
  .nobel-section__sub-nav__menu li.current a, .nobel-section__sub-nav__menu li:hover a, .nobel-section__sub-nav__menu li:focus a {
    background-color: #033963;
    background-color: var(--clr-accent--source);
    color: #ffffff;
    color: var(--clr-whitish--source);
    text-decoration: none;
    font-weight: 600;
    transition: all 300ms ease-in-out;
    border-radius: 5px;
  }
  .nobel-section__sub-nav__menu a {
    display: block;
    padding: 14px 0;
    text-align: center;
    font-weight: 500;
    color: #033963;
    color: var(--clr-accent--source);
    transition: all 300ms ease-in-out;
  }
}
.nobel-section + .nobel-section {
  padding-top: 10px;
}
@media only screen and (min-width: 920px) {
  .nobel-section + .nobel-section {
    padding-top: 20px;
  }
}
.nobel-section + .nobel-section .nobel-section__title {
  margin-bottom: 40px;
}
@media only screen and (min-width: 920px) {
  .nobel-section + .nobel-section .nobel-section__title {
    margin-bottom: 60px;
  }
}

.nobel-container {
  max-width: 1140px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .nobel-container {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 1300px) {
  .nobel-container {
    max-width: 1240px;
  }
}
@media only screen and (min-width: 1400px) {
  .nobel-container {
    max-width: 1340px;
  }
}
@media only screen and (min-width: 1600px) {
  .nobel-container {
    max-width: 1440px;
  }
}

.nobel-bg-primary--100 {
  background-color: var(--clr-primary--100);
}

.nobel-text-primary--100 {
  color: var(--clr-primary--100);
}

.nobel-border-primary--100 {
  border-color: var(--clr-primary--100);
}

.nobel-bg-primary--200 {
  background-color: var(--clr-primary--200);
}

.nobel-text-primary--200 {
  color: var(--clr-primary--200);
}

.nobel-border-primary--200 {
  border-color: var(--clr-primary--200);
}

.nobel-bg-primary--300 {
  background-color: var(--clr-primary--300);
}

.nobel-text-primary--300 {
  color: var(--clr-primary--300);
}

.nobel-border-primary--300 {
  border-color: var(--clr-primary--300);
}

.nobel-bg-primary--400 {
  background-color: var(--clr-primary--400);
}

.nobel-text-primary--400 {
  color: var(--clr-primary--400);
}

.nobel-border-primary--400 {
  border-color: var(--clr-primary--400);
}

.nobel-bg-primary--500 {
  background-color: var(--clr-primary--500);
}

.nobel-text-primary--500 {
  color: var(--clr-primary--500);
}

.nobel-border-primary--500 {
  border-color: var(--clr-primary--500);
}

.nobel-bg-primary--dim-55-percent {
  background-color: rgba(105, 7, 2, 0.55);
  background-color: var(--clr-primary--dim-55-percent);
}

.nobel-text-primary--dim-55-percent {
  color: rgba(105, 7, 2, 0.55);
  color: var(--clr-primary--dim-55-percent);
}

.nobel-border-primary--dim-55-percent {
  border-color: rgba(105, 7, 2, 0.55);
  border-color: var(--clr-primary--dim-55-percent);
}

.nobel-bg-primary--dim-70-percent {
  background-color: rgba(105, 7, 2, 0.7);
  background-color: var(--clr-primary--dim-70-percent);
}

.nobel-text-primary--dim-70-percent {
  color: rgba(105, 7, 2, 0.7);
  color: var(--clr-primary--dim-70-percent);
}

.nobel-border-primary--dim-70-percent {
  border-color: rgba(105, 7, 2, 0.7);
  border-color: var(--clr-primary--dim-70-percent);
}

.nobel-bg-primary--dim-85-percent {
  background-color: rgba(105, 7, 2, 0.85);
  background-color: var(--clr-primary--dim-85-percent);
}

.nobel-text-primary--dim-85-percent {
  color: rgba(105, 7, 2, 0.85);
  color: var(--clr-primary--dim-85-percent);
}

.nobel-border-primary--dim-85-percent {
  border-color: rgba(105, 7, 2, 0.85);
  border-color: var(--clr-primary--dim-85-percent);
}

.nobel-bg-primary--source {
  background-color: #690702;
  background-color: var(--clr-primary--source);
}

.nobel-text-primary--source {
  color: #690702;
  color: var(--clr-primary--source);
}

.nobel-border-primary--source {
  border-color: #690702;
  border-color: var(--clr-primary--source);
}

.nobel-bg-accent--100 {
  background-color: var(--clr-accent--100);
}

.nobel-text-accent--100 {
  color: var(--clr-accent--100);
}

.nobel-border-accent--100 {
  border-color: var(--clr-accent--100);
}

.nobel-bg-accent--200 {
  background-color: var(--clr-accent--200);
}

.nobel-text-accent--200 {
  color: var(--clr-accent--200);
}

.nobel-border-accent--200 {
  border-color: var(--clr-accent--200);
}

.nobel-bg-accent--300 {
  background-color: var(--clr-accent--300);
}

.nobel-text-accent--300 {
  color: var(--clr-accent--300);
}

.nobel-border-accent--300 {
  border-color: var(--clr-accent--300);
}

.nobel-bg-accent--400 {
  background-color: var(--clr-accent--400);
}

.nobel-text-accent--400 {
  color: var(--clr-accent--400);
}

.nobel-border-accent--400 {
  border-color: var(--clr-accent--400);
}

.nobel-bg-accent--500 {
  background-color: var(--clr-accent--500);
}

.nobel-text-accent--500 {
  color: var(--clr-accent--500);
}

.nobel-border-accent--500 {
  border-color: var(--clr-accent--500);
}

.nobel-bg-accent--dim-85-percent {
  background-color: rgba(3, 57, 99, 0.85);
  background-color: var(--clr-accent--dim-85-percent);
}

.nobel-text-accent--dim-85-percent {
  color: rgba(3, 57, 99, 0.85);
  color: var(--clr-accent--dim-85-percent);
}

.nobel-border-accent--dim-85-percent {
  border-color: rgba(3, 57, 99, 0.85);
  border-color: var(--clr-accent--dim-85-percent);
}

.nobel-bg-accent--dim-25-percent {
  background-color: rgba(3, 57, 99, 0.25);
  background-color: var(--clr-accent--dim-25-percent);
}

.nobel-text-accent--dim-25-percent {
  color: rgba(3, 57, 99, 0.25);
  color: var(--clr-accent--dim-25-percent);
}

.nobel-border-accent--dim-25-percent {
  border-color: rgba(3, 57, 99, 0.25);
  border-color: var(--clr-accent--dim-25-percent);
}

.nobel-bg-accent--source {
  background-color: #033963;
  background-color: var(--clr-accent--source);
}

.nobel-text-accent--source {
  color: #033963;
  color: var(--clr-accent--source);
}

.nobel-border-accent--source {
  border-color: #033963;
  border-color: var(--clr-accent--source);
}

.nobel-bg-divider--100 {
  background-color: var(--clr-divider--100);
}

.nobel-text-divider--100 {
  color: var(--clr-divider--100);
}

.nobel-border-divider--100 {
  border-color: var(--clr-divider--100);
}

.nobel-bg-divider--200 {
  background-color: var(--clr-divider--200);
}

.nobel-text-divider--200 {
  color: var(--clr-divider--200);
}

.nobel-border-divider--200 {
  border-color: var(--clr-divider--200);
}

.nobel-bg-divider--300 {
  background-color: var(--clr-divider--300);
}

.nobel-text-divider--300 {
  color: var(--clr-divider--300);
}

.nobel-border-divider--300 {
  border-color: var(--clr-divider--300);
}

.nobel-bg-divider--400 {
  background-color: var(--clr-divider--400);
}

.nobel-text-divider--400 {
  color: var(--clr-divider--400);
}

.nobel-border-divider--400 {
  border-color: var(--clr-divider--400);
}

.nobel-bg-divider--500 {
  background-color: var(--clr-divider--500);
}

.nobel-text-divider--500 {
  color: var(--clr-divider--500);
}

.nobel-border-divider--500 {
  border-color: var(--clr-divider--500);
}

.nobel-bg-divider--blue-dim-20-percent {
  background-color: rgba(16, 79, 134, 0.2);
  background-color: var(--clr-divider--blue-dim-20-percent);
}

.nobel-text-divider--blue-dim-20-percent {
  color: rgba(16, 79, 134, 0.2);
  color: var(--clr-divider--blue-dim-20-percent);
}

.nobel-border-divider--blue-dim-20-percent {
  border-color: rgba(16, 79, 134, 0.2);
  border-color: var(--clr-divider--blue-dim-20-percent);
}

.nobel-bg-divider--source-blue {
  background-color: #104F86;
  background-color: var(--clr-divider--source-blue);
}

.nobel-text-divider--source-blue {
  color: #104F86;
  color: var(--clr-divider--source-blue);
}

.nobel-border-divider--source-blue {
  border-color: #104F86;
  border-color: var(--clr-divider--source-blue);
}

.nobel-bg-injection--100 {
  background-color: var(--clr-injection--100);
}

.nobel-text-injection--100 {
  color: var(--clr-injection--100);
}

.nobel-border-injection--100 {
  border-color: var(--clr-injection--100);
}

.nobel-bg-injection--200 {
  background-color: var(--clr-injection--200);
}

.nobel-text-injection--200 {
  color: var(--clr-injection--200);
}

.nobel-border-injection--200 {
  border-color: var(--clr-injection--200);
}

.nobel-bg-injection--300 {
  background-color: var(--clr-injection--300);
}

.nobel-text-injection--300 {
  color: var(--clr-injection--300);
}

.nobel-border-injection--300 {
  border-color: var(--clr-injection--300);
}

.nobel-bg-injection--400 {
  background-color: var(--clr-injection--400);
}

.nobel-text-injection--400 {
  color: var(--clr-injection--400);
}

.nobel-border-injection--400 {
  border-color: var(--clr-injection--400);
}

.nobel-bg-injection--500 {
  background-color: var(--clr-injection--500);
}

.nobel-text-injection--500 {
  color: var(--clr-injection--500);
}

.nobel-border-injection--500 {
  border-color: var(--clr-injection--500);
}

.nobel-bg-injection--dim-85-percent {
  background-color: rgba(207, 185, 74, 0.85);
  background-color: var(--clr-injection--dim-85-percent);
}

.nobel-text-injection--dim-85-percent {
  color: rgba(207, 185, 74, 0.85);
  color: var(--clr-injection--dim-85-percent);
}

.nobel-border-injection--dim-85-percent {
  border-color: rgba(207, 185, 74, 0.85);
  border-color: var(--clr-injection--dim-85-percent);
}

.nobel-bg-injection--source {
  background-color: #CFBA4B;
  background-color: var(--clr-injection--source);
}

.nobel-text-injection--source {
  color: #CFBA4B;
  color: var(--clr-injection--source);
}

.nobel-border-injection--source {
  border-color: #CFBA4B;
  border-color: var(--clr-injection--source);
}

.nobel-bg-whitish--100 {
  background-color: var(--clr-whitish--100);
}

.nobel-text-whitish--100 {
  color: var(--clr-whitish--100);
}

.nobel-border-whitish--100 {
  border-color: var(--clr-whitish--100);
}

.nobel-bg-whitish--200 {
  background-color: var(--clr-whitish--200);
}

.nobel-text-whitish--200 {
  color: var(--clr-whitish--200);
}

.nobel-border-whitish--200 {
  border-color: var(--clr-whitish--200);
}

.nobel-bg-whitish--300 {
  background-color: var(--clr-whitish--300);
}

.nobel-text-whitish--300 {
  color: var(--clr-whitish--300);
}

.nobel-border-whitish--300 {
  border-color: var(--clr-whitish--300);
}

.nobel-bg-whitish--400 {
  background-color: var(--clr-whitish--400);
}

.nobel-text-whitish--400 {
  color: var(--clr-whitish--400);
}

.nobel-border-whitish--400 {
  border-color: var(--clr-whitish--400);
}

.nobel-bg-whitish--500 {
  background-color: var(--clr-whitish--500);
}

.nobel-text-whitish--500 {
  color: var(--clr-whitish--500);
}

.nobel-border-whitish--500 {
  border-color: var(--clr-whitish--500);
}

.nobel-bg-whitish--dim-40-precent {
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--clr-whitish--dim-40-precent);
}

.nobel-text-whitish--dim-40-precent {
  color: rgba(255, 255, 255, 0.4);
  color: var(--clr-whitish--dim-40-precent);
}

.nobel-border-whitish--dim-40-precent {
  border-color: rgba(255, 255, 255, 0.4);
  border-color: var(--clr-whitish--dim-40-precent);
}

.nobel-bg-whitish--source {
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
}

.nobel-text-whitish--source {
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.nobel-border-whitish--source {
  border-color: #ffffff;
  border-color: var(--clr-whitish--source);
}

.nobel-bg-blackish--100 {
  background-color: var(--clr-blackish--100);
}

.nobel-text-blackish--100 {
  color: var(--clr-blackish--100);
}

.nobel-border-blackish--100 {
  border-color: var(--clr-blackish--100);
}

.nobel-bg-blackish--200 {
  background-color: var(--clr-blackish--200);
}

.nobel-text-blackish--200 {
  color: var(--clr-blackish--200);
}

.nobel-border-blackish--200 {
  border-color: var(--clr-blackish--200);
}

.nobel-bg-blackish--300 {
  background-color: var(--clr-blackish--300);
}

.nobel-text-blackish--300 {
  color: var(--clr-blackish--300);
}

.nobel-border-blackish--300 {
  border-color: var(--clr-blackish--300);
}

.nobel-bg-blackish--dim-300--10-percent {
  background-color: rgba(70, 10, 6, 0.1);
  background-color: var(--clr-blackish--dim-300--10-percent);
}

.nobel-text-blackish--dim-300--10-percent {
  color: rgba(70, 10, 6, 0.1);
  color: var(--clr-blackish--dim-300--10-percent);
}

.nobel-border-blackish--dim-300--10-percent {
  border-color: rgba(70, 10, 6, 0.1);
  border-color: var(--clr-blackish--dim-300--10-percent);
}

.nobel-bg-blackish--400 {
  background-color: var(--clr-blackish--400);
}

.nobel-text-blackish--400 {
  color: var(--clr-blackish--400);
}

.nobel-border-blackish--400 {
  border-color: var(--clr-blackish--400);
}

.nobel-bg-blackish--500 {
  background-color: var(--clr-blackish--500);
}

.nobel-text-blackish--500 {
  color: var(--clr-blackish--500);
}

.nobel-border-blackish--500 {
  border-color: var(--clr-blackish--500);
}

.nobel-bg-blackish--dim-70-percent {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--clr-blackish--dim-70-percent);
}

.nobel-text-blackish--dim-70-percent {
  color: rgba(0, 0, 0, 0.7);
  color: var(--clr-blackish--dim-70-percent);
}

.nobel-border-blackish--dim-70-percent {
  border-color: rgba(0, 0, 0, 0.7);
  border-color: var(--clr-blackish--dim-70-percent);
}

.nobel-bg-blackish--vr-one {
  background-color: #212121;
  background-color: var(--clr-blackish--vr-one);
}

.nobel-text-blackish--vr-one {
  color: #212121;
  color: var(--clr-blackish--vr-one);
}

.nobel-border-blackish--vr-one {
  border-color: #212121;
  border-color: var(--clr-blackish--vr-one);
}

.nobel-bg-blackish--vr-two {
  background-color: #707070;
  background-color: var(--clr-blackish--vr-two);
}

.nobel-text-blackish--vr-two {
  color: #707070;
  color: var(--clr-blackish--vr-two);
}

.nobel-border-blackish--vr-two {
  border-color: #707070;
  border-color: var(--clr-blackish--vr-two);
}

.nobel-bg-blackish--vr-three {
  background-color: #CFD0D7;
  background-color: var(--clr-blackish--vr-three);
}

.nobel-text-blackish--vr-three {
  color: #CFD0D7;
  color: var(--clr-blackish--vr-three);
}

.nobel-border-blackish--vr-three {
  border-color: #CFD0D7;
  border-color: var(--clr-blackish--vr-three);
}

.nobel-bg-blackish--vr-three-dim-90-percent {
  background-color: rgba(207, 208, 215, 0.9);
  background-color: var(--clr-blackish--vr-three-dim-90-percent);
}

.nobel-text-blackish--vr-three-dim-90-percent {
  color: rgba(207, 208, 215, 0.9);
  color: var(--clr-blackish--vr-three-dim-90-percent);
}

.nobel-border-blackish--vr-three-dim-90-percent {
  border-color: rgba(207, 208, 215, 0.9);
  border-color: var(--clr-blackish--vr-three-dim-90-percent);
}

.nobel-bg-blackish--vr-two-dim-25-percent {
  background-color: rgba(112, 112, 112, 0.25);
  background-color: var(--clr-blackish--vr-two-dim-25-percent);
}

.nobel-text-blackish--vr-two-dim-25-percent {
  color: rgba(112, 112, 112, 0.25);
  color: var(--clr-blackish--vr-two-dim-25-percent);
}

.nobel-border-blackish--vr-two-dim-25-percent {
  border-color: rgba(112, 112, 112, 0.25);
  border-color: var(--clr-blackish--vr-two-dim-25-percent);
}

.nobel-bg-blackish--source {
  background-color: #000000;
  background-color: var(--clr-blackish--source);
}

.nobel-text-blackish--source {
  color: #000000;
  color: var(--clr-blackish--source);
}

.nobel-border-blackish--source {
  border-color: #000000;
  border-color: var(--clr-blackish--source);
}

.nobel-bg-text--100 {
  background-color: var(--clr-text--100);
}

.nobel-text-text--100 {
  color: var(--clr-text--100);
}

.nobel-border-text--100 {
  border-color: var(--clr-text--100);
}

.nobel-bg-text--200 {
  background-color: var(--clr-text--200);
}

.nobel-text-text--200 {
  color: var(--clr-text--200);
}

.nobel-border-text--200 {
  border-color: var(--clr-text--200);
}

.nobel-bg-text--300 {
  background-color: var(--clr-text--300);
}

.nobel-text-text--300 {
  color: var(--clr-text--300);
}

.nobel-border-text--300 {
  border-color: var(--clr-text--300);
}

.nobel-bg-text--400 {
  background-color: var(--clr-text--400);
}

.nobel-text-text--400 {
  color: var(--clr-text--400);
}

.nobel-border-text--400 {
  border-color: var(--clr-text--400);
}

.nobel-bg-text--500 {
  background-color: var(--clr-text--500);
}

.nobel-text-text--500 {
  color: var(--clr-text--500);
}

.nobel-border-text--500 {
  border-color: var(--clr-text--500);
}

.nobel-bg-text--source {
  background-color: #333333;
  background-color: var(--clr-text--source);
}

.nobel-text-text--source {
  color: #333333;
  color: var(--clr-text--source);
}

.nobel-border-text--source {
  border-color: #333333;
  border-color: var(--clr-text--source);
}

.nobel-bg-shadow--dim-25-percent {
  background-color: rgba(120, 120, 120, 0.25);
  background-color: var(--clr-shadow--dim-25-percent);
}

.nobel-text-shadow--dim-25-percent {
  color: rgba(120, 120, 120, 0.25);
  color: var(--clr-shadow--dim-25-percent);
}

.nobel-border-shadow--dim-25-percent {
  border-color: rgba(120, 120, 120, 0.25);
  border-color: var(--clr-shadow--dim-25-percent);
}

.nobel-bg-shadow--source-1 {
  background-color: #797979;
  background-color: var(--clr-shadow--source-1);
}

.nobel-text-shadow--source-1 {
  color: #797979;
  color: var(--clr-shadow--source-1);
}

.nobel-border-shadow--source-1 {
  border-color: #797979;
  border-color: var(--clr-shadow--source-1);
}

.nobel-bg-border--source {
  background-color: #B7B8C0;
  background-color: var(--clr-border--source);
}

.nobel-text-border--source {
  color: #B7B8C0;
  color: var(--clr-border--source);
}

.nobel-border-border--source {
  border-color: #B7B8C0;
  border-color: var(--clr-border--source);
}

.nobel-bx-shadow-header--source-1 {
  box-shadow: 0px 4px 15px rgba(159, 159, 159, 0.25);
  box-shadow: var(--bx-shadow-header--source-1);
}

.nobel-font-family--montserrat {
  font-family: Montserrat, sans-serif;
  font-family: var(--font-family--montserrat);
}

.nobel-font-family--mulish {
  font-family: Mulish, sans-serif;
  font-family: var(--font-family--mulish);
}

.nobel-font-family--sans-serif {
  font-family: sans-serif;
  font-family: var(--font-family--sans-serif);
}

.nobel-font-family--serif {
  font-family: serif;
  font-family: var(--font-family--serif);
}

.nobel-font-size--xs {
  font-size: 15px;
  font-size: var(--font-size--xs);
}

.nobel-font-size--sm {
  font-size: 16px;
  font-size: var(--font-size--sm);
}

.nobel-font-size--md {
  font-size: 17px;
  font-size: var(--font-size--md);
}

.nobel-font-size--lg {
  font-size: 19px;
  font-size: var(--font-size--lg);
}

.nobel-font-size--20 {
  font-size: px;
  font-size: var(--font-size--20);
}

.nobel-font-size--22 {
  font-size: px;
  font-size: var(--font-size--22);
}

.nobel-font-size--26 {
  font-size: px;
  font-size: var(--font-size--26);
}

.nobel-font-size--27 {
  font-size: px;
  font-size: var(--font-size--27);
}

.nobel-font-size--29 {
  font-size: px;
  font-size: var(--font-size--29);
}

.nobel-font-size--xl {
  font-size: 30px;
  font-size: var(--font-size--xl);
}

.nobel-font-size--xxl {
  font-size: 40px;
  font-size: var(--font-size--xxl);
}

.nobel-font-size--icon-ex {
  font-size: 60px;
  font-size: var(--font-size--icon-ex);
}

.nobel-font-size--icon-extreme {
  font-size: 70px;
  font-size: var(--font-size--icon-extreme);
}

.nobel-font-size--base {
  font-size: 16px;
  font-size: var(--font-size--base);
}

.nobel-font-weight--light {
  font-weight: 300;
  font-weight: var(--font-weight--light);
}

.nobel-font-weight--regular {
  font-weight: 400;
  font-weight: var(--font-weight--regular);
}

.nobel-font-weight--medium {
  font-weight: 500;
  font-weight: var(--font-weight--medium);
}

.nobel-font-weight--semi-bold {
  font-weight: 600;
  font-weight: var(--font-weight--semi-bold);
}

.nobel-font-weight--bold {
  font-weight: 700;
  font-weight: var(--font-weight--bold);
}

.nobel-font-weight--bold-ex {
  font-weight: 800;
  font-weight: var(--font-weight--bold-ex);
}

.nobel-font-style--italic {
  font-style: italic;
  font-style: var(--font-style--italic);
}

.nobel-font-style--oblique {
  font-style: oblique;
  font-style: var(--font-style--oblique);
}

.nobel-font-style--normal {
  font-style: normal;
  font-style: var(--font-style--normal);
}

.nobel-font-style--initial {
  font-style: initial;
  font-style: var(--font-style--initial);
}

.nobel-font-style--inherit {
  font-style: inherit;
  font-style: var(--font-style--inherit);
}

.nobel-d--block {
  display: block;
  display: var(--d-block);
}

.nobel-d--i-block {
  display: inline-block;
  display: var(--d-i-block);
}

.nobel-d--inline {
  display: inline;
  display: var(--d-inline);
}

.nobel-d--table {
  display: table;
  display: var(--d-table);
}

.nobel-d--table-c {
  display: table-cell;
  display: var(--d-table-c);
}

.nobel-d--flex {
  display: flex;
  display: var(--d-flex);
}

.nobel-d--i-flex {
  display: inline-flex;
  display: var(--d-i-flex);
}

.nobel-d--grid {
  display: grid;
  display: var(--d-grid);
}

.col-1--of-12 {
  width: 8.3333333333%;
  box-sizing: content-box;
}

.col-2--of-12 {
  width: 16.6666666667%;
  box-sizing: content-box;
}

.col-3--of-12 {
  width: 25%;
  box-sizing: content-box;
}

.col-4--of-12 {
  width: 33.3333333333%;
  box-sizing: content-box;
}

.col-5--of-12 {
  width: 41.6666666667%;
  box-sizing: content-box;
}

.col-6--of-12 {
  width: 50%;
  box-sizing: content-box;
}

.col-7--of-12 {
  width: 58.3333333333%;
  box-sizing: content-box;
}

.col-8--of-12 {
  width: 66.6666666667%;
  box-sizing: content-box;
}

.col-9--of-12 {
  width: 75%;
  box-sizing: content-box;
}

.col-10--of-12 {
  width: 83.3333333333%;
  box-sizing: content-box;
}

.col-11--of-12 {
  width: 91.6666666667%;
  box-sizing: content-box;
}

.col-12--of-12 {
  width: 100%;
  box-sizing: content-box;
}

@media only screen and (min-width: 768px) {
  .col-md-1--of-12 {
    width: 8.3333333333%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-2--of-12 {
    width: 16.6666666667%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-3--of-12 {
    width: 25%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-4--of-12 {
    width: 33.3333333333%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-5--of-12 {
    width: 41.6666666667%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-6--of-12 {
    width: 50%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-7--of-12 {
    width: 58.3333333333%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-8--of-12 {
    width: 66.6666666667%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-9--of-12 {
    width: 75%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-10--of-12 {
    width: 83.3333333333%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-11--of-12 {
    width: 91.6666666667%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-12--of-12 {
    width: 100%;
    box-sizing: content-box;
  }

  .col-md-35--of-100 {
    width: 35%;
    box-sizing: content-box;
  }

  .col-md-39--of-100 {
    width: 39%;
    box-sizing: content-box;
  }

  .col-md-40--of-100 {
    width: 40%;
    box-sizing: content-box;
  }
}
.nobel-gutter {
  margin: 0 -15px;
}
.nobel-gutter .col-1--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-2--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-3--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-4--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-5--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-6--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-7--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-8--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-9--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-10--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-11--of-12 {
  padding: 0 15px;
}
.nobel-gutter .col-12--of-12 {
  padding: 0 15px;
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-1--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-2--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-3--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-4--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-5--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-6--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-7--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-8--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-9--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-10--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-11--of-12 {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .nobel-gutter .col-md-12--of-12 {
    padding: 0 15px;
  }
}

.nobel-flex {
  display: flex;
  display: var(--d-flex);
}
.nobel-flex--wr-flow {
  flex-flow: wrap row;
}
.nobel-flex--wc-flow {
  flex-flow: wrap column;
}
.nobel-flex--ai-start {
  align-items: flex-start;
}
.nobel-flex--ai-end {
  align-items: flex-end;
}
.nobel-flex--ai-center {
  align-items: center;
}
.nobel-flex--ai-stretch {
  align-items: stretch;
}
.nobel-flex--ai-baseline {
  align-items: baseline;
}
.nobel-flex--jc-start {
  justify-content: flex-start;
}
.nobel-flex--jc-end {
  justify-content: flex-end;
}
.nobel-flex--jc-center {
  justify-content: center;
}
.nobel-flex--jc-between {
  justify-content: space-between;
}
.nobel-flex--jc-around {
  justify-content: space-around;
}
.nobel-flex--jc-evenly {
  justify-content: space-evenly;
}

.nobel-element--overlay {
  position: relative;
  position: relative;
}
.nobel-element--overlay .nobel-container {
  position: relative;
  z-index: 2;
}
.nobel-element--overlay .nobel-container * {
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-element--overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--clr-blackish--dim-70-percent);
}

.nobel-component--overlay {
  position: relative;
  position: relative;
}
.nobel-component--overlay > * {
  position: relative;
  z-index: 2;
}
.nobel-component--overlay > * * {
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.nobel-component--overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--clr-blackish--dim-70-percent);
}
.nobel-component--overlay--bg-primary--source {
  position: relative;
}
.nobel-component--overlay--bg-primary--source:after {
  content: "";
  position: absolute;
  background-color: rgba(105, 7, 2, 0.7);
  background-color: var(--clr-primary--dim-70-percent);
}

@media only screen and (min-width: 920px) {
  .nobel-clip {
    overflow: hidden;
  }
}

@media only screen and (min-width: 920px) {
  .nobel-clip--inner-top-point {
    clip-path: polygon(50% 26%, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media only screen and (min-width: 920px) {
  .nobel-clip--parallelogram-right {
    clip-path: polygon(0 0, 100% 0, 76% 100%, 0% 100%);
  }
}

.nobel-translate-horizontal--center {
  transform: translateX(-50%);
}

.nobel-translate-vertical--center {
  transform: translateY(-50%);
}

.nobel-left-center {
  left: 50%;
}

.nobel-top-50-px-negative {
  top: -50px;
}

.nobel-top-25-px-positive {
  top: 25px;
}

.nobel-translate-y-center {
  top: 50%;
  transform: translateY(-50%);
}

.nobel-rounded--1 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--2 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--3 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--4 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--5 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--6 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--7 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--8 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--9 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--10 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--11 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--12 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--13 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--14 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--15 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--16 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--17 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--18 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--19 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--20 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--21 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--22 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--23 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--24 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--25 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--26 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--27 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--28 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--29 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--30 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--31 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--32 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--33 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--34 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--35 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--36 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--37 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--38 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--39 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--40 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--41 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--42 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--43 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--44 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--45 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--46 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--47 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--48 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--49 {
  border-radius: var(--rounded--8);
}

.nobel-rounded--50 {
  border-radius: var(--rounded--8);
}