@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, container) {
    max-width: 1140px;
    @include abstract.mq(max, mobile, xl) {
        padding: 0 15px;
    }
    @include abstract.mq(min, desktop, sm) {
        max-width: 1240px;
    }
    @include abstract.mq(min, desktop, md) {
        max-width: 1340px;
    }
    @include abstract.mq(min, desktop, lg) {
        max-width: 1440px;
    }
    width: 100%;
}