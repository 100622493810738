@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, card) {
    @include abstract.shadow($x:0px, $y:4px, $blur: 15px, $shade:null, $color: map-get(map-get(abstract.$colors, shadow), dim-25-percent));
    @include abstract.inner-class(#{ -- }, program) {
        @include abstract.rounded(8px);
        @include abstract.shadow($x:0px, $y:4px, $blur: 15px, $shade:null, $color: map-get(map-get(abstract.$colors, shadow), dim-25-percent));
        @include abstract.getColor(background-color, whitish, source);

        @include abstract.prefix-class(nobel, card__figure) {
            max-height: 346px;
            overflow: hidden;
            
            img {
                object-fit: cover;
                height: 100%;
                max-height: 346px;
            }

            @include abstract.prefix-class(nobel, card__title) {
                bottom: 20px;
                left: 30px;
                z-index: 2;
                line-height: 1.2;
            }

            @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) {
                bottom: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 10px;
                @include abstract.getColor(background-color, accent, source);
                transition: all 500ms ease-in-out 0s;
            }
            
        }

        &.nobel-card--no-bar {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) {
                    height: 0px;
                }
            }
        }
        
        @include abstract.nth(2) {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) { 
                    @include abstract.getColor(background-color, primary, source);
                }
            }
        }
        
        @include abstract.nth(2n+3) {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) { 
                    @include abstract.getColor(background-color, primary, source);
                }
            }
        }
        
        @include abstract.nth(3) {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) { 
                    @include abstract.getColor(background-color, injection, source);
                }
            }
        }
        
        @include abstract.nth(3n+3) {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) { 
                    @include abstract.getColor(background-color, injection, source);
                }
            }
        }
        
        @include abstract.prefix-class(nobel, card__content) {
            padding: 30px;

            @include abstract.prefix-class(nobel, card__title) {
                @include abstract.fetchGlobalVars(font-weight, typography, abstract.$fonts, weight, semi-bold);
                @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, 20);
                margin-bottom: 10px;
            }
        }
        
        @include abstract.hover-state {
            @include abstract.prefix-class(nobel, card__figure) {
                @include abstract.pseudo($loc: after, $cnt:null, $pos: absolute) {
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    @include abstract.getColor(background-color, primary, dim-55-percent);
                    transition: all 500ms ease-in-out 0s;
                }
            }
        }

        &.nobel-card--filter {
            &.nobel-filter--show {
                display: block;
            }
            &.nobel-filter--hide {
                display: none;
            }
        }

    }

    @include abstract.inner-class(#{ __ }, content) {

    }

    @include abstract.inner-class(#{ -- }, grid) {
        @include abstract.display(flex);
        box-shadow: none;
    }

    @include abstract.inner-class(#{ -- }, menu-box) {
        
        @include abstract.rounded(8px);
        @include abstract.shadow($x:0px, $y:4px, $blur: 15px, $shade:null, $color: map-get(map-get(abstract.$colors, shadow), dim-25-percent));
        @include abstract.getColor(background-color, whitish, source);
        overflow: hidden;
        
        & > a, & > div {
            padding: 60px 0;
            transition: all 300ms ease-in-out;
            cursor: pointer;

            @include abstract.hover-state {
                @include abstract.getColor(background-color, blackish, dim-300--10-percent);
            }
        }

        figure {
            margin-bottom: 20px;

            svg > path {
                @include abstract.getColor(stroke, blackish, source);
                
                @include abstract.hover-state {
                    @include abstract.getColor(stroke, whitish, source);
                }
            }
        }
    }

    @include abstract.inner-class(#{ -- }, vacancy) {
        padding: 0 20px;
        
        @include abstract.mq(min, tablet, sm) {
            padding: 0 40px;
        }
        &.nobel-accordion .nobel-accordion__header,
        &.nobel-accordion .nobel-accordion__content {
            padding: 20px 0;
            
            @include abstract.mq(min, tablet, sm) {
                padding: 40px 0;
            }

            &.invisible {
                padding: 0;
            }
        }

    }
}