@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, form) {
    @include abstract.inner-class(#{ __ }, group) {
        // margin-bottom: 20px;
    }

    @include abstract.inner-class(#{ __ }, control) {
        @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, base);
        @include abstract.getColor(background-color, whitish, source);
        padding: 0 15px;
        outline: none !important;

        @include abstract.hover-state {
            @include abstract.getColor(border-color, primary, source);
        }
    }
    
    @include abstract.inner-class(#{ __ }, label) {
        @include abstract.display(block);
    }
    @include abstract.inner-class(#{ __ }, addons) {
        padding-left: 50px;
        @include abstract.getColor(color, blackish, source);
    }

    &__input, &__select {
        height: 42px;
        line-height: 42px;
    }


}

/*Bootstrap Calendar*/
.form-container {

}
.calendar-popup {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 8px 24px rgba(black, .08);
    opacity: 0;
    padding: 10px;
    position: absolute;
    top: calc(100% + 20px );
    transition: all .1s linear;
    width: 100%;
    opacity: 0;
    // display: none;

    .month-and-year {
       align-items: center;
       display: flex;
       text-align: center;
       text-transform: uppercase;

       h4 {
          width: 100%;
       }
    }

    .button {
       background-color: transparent;
       border: none;
       font-weight: bold;
       outline: none;
       position: absolute;
       top: 15px;

       &:hover {
          cursor: pointer;
       }

       &.prev {
          left: 10px;
       }

       &.next {
          right: 10px;
       }
    }

    table {
       font-size: 12px;
       width: 100%;

       tr {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          padding: 5px 0;

          th,
          td {
             text-align: center;
          }
       }

       .day {
          &:hover {
             cursor: pointer;
          }
       }
    }
 }

 
.open {
    .calendar-popup {
        opacity: 1;
        position: fixed;
        z-index: 100;
        top: 15%;
        max-width: 300px;
    }
}