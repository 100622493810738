@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, footer) {
    @include abstract.inner-class(#{__}, top) {
        padding: 35px 0;
    }
    @include abstract.inner-class(#{__}, brand) {
        position: relative;
        top: -60px;
        left: 0;
        height: 161px;
        border-radius: 0 0 20px 20px;
        padding: 20px;
        // margin-bottom: 20px;

        & + * {
            position: relative;
            top: -30px;
        }
    }
    @include abstract.fetchGlobalVars(font-family, $flag: typography, $varname: abstract.$fonts, $type: family, $output: mulish);
    // @include abstract.prefix-class(nobel, btn) {}
    @include abstract.inner-class(#{__}, menu) {
        li + li {
            margin-top: 10px;
        }

        a {
            @include abstract.hover-state {
                @include abstract.getColor(color, injection, source);
            }
        }
    }
}