@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, clinical-services) {
    @include abstract.inner-class(#{ -- }, list) {
        @include abstract.prefix-class(nobel, card--menu-box) {
            @include abstract.getColor(background-color, whitish, source);

            @include abstract.hover-state {
                @include abstract.getColor(background-color, primary, source);

                figure {
                    svg > path {
                        @include abstract.getColor(stroke, whitish, source);
                        stroke-opacity: 1;
                    }

                    svg.has-fill > path {
                        @include abstract.getColor(stroke, whitish, source);
                        @include abstract.getColor(fill, whitish, source);
                    }
                }

                article {
                    & > span.nobel-text-accent--source {
                        @include abstract.getColor(color, whitish, source);
                    }
                }
            }
        }
    }
}