@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, dropdown) {
    position: relative;
    
    &--menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        visibility: hidden;
        opacity: 0;

        min-width: 200px;
        max-width: max-content;

        a {
            display: block;
            padding: 8px 15px;
        }
    }

    @include abstract.hover-state {
        @include abstract.prefix-class(nobel, dropdown--menu) {
            visibility: visible;
            opacity: 1;
        }
    }
}