@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, left-center) {
    left: 50%;
}

@include abstract.prefix-class(nobel, top-50-px-negative) {
    top: -50px;
}

@include abstract.prefix-class(nobel, top-25-px-positive) {
    top: 25px;
}

@include abstract.prefix-class(nobel, translate-y-center) {
    top: 50%;
    transform: translateY(-50%);
}