@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, rich-text) {
    @include abstract.inner-class(#{ __ }, article) {
        @include abstract.fetchGlobalVars(font-weight, typography, abstract.$fonts, weight, medium);
        strong  {
            @include abstract.fetchGlobalVars(font-weight, typography, abstract.$fonts, weight, bold);
        }
        & > * + * {
            margin-top: 1.3rem;
        }
        h1, h2, h3, h4, h5, h6 {
            & + * {
                margin-top: 1rem;
            }

            @include abstract.fetchGlobalVars(font-weight, typography, abstract.$fonts, weight, bold);
        }

        * {
            & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
                margin-top: 1.5rem;
            }
        }

        h1 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, xl);
        }

        h2 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, xl);
        }

        h3 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, 20);
        }

        h4 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, lg);
        }

        h5 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, md);
        }

        h6 {
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, sm);
        }

        ul, ol {
            padding-left: 30px;

            li + li {
                margin-top: 0.5rem;
            }
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: number;
        }

        img {
            max-width: 100%;
            margin: 0 auto;
            display: block;

            + * {
                margin-top: 1.5rem;
            }
        }

        * + img {
            margin-top: 1.8rem;
        }

        blockquote {
            padding: 20px 30px;
            border-radius: 15px;
            @include abstract.fetchGlobalVars(font-size, typography, abstract.$fonts, size, 20);
            @include abstract.fetchGlobalVars(font-style, typography, abstract.$fonts, style, italic);
            @include abstract.fetchGlobalVars(font-weight, typography, abstract.$fonts, weight, bold);
            @include abstract.fetchGlobalVars(font-family, typography, abstract.$fonts, family, mulish);
            @include abstract.getShadow(box-shadow, header, source-1);
            // margin-left: auto;
            // margin-right: auto;
            max-width: 900px;

            & + * {
                margin-top: 1.5rem;
            }
        }

        * + blockquote {
            margin-top: 2rem;
        }
    }

    @include abstract.inner-class(#{ __ }, feature-image) {
        margin-bottom: 2rem;
    }
}