@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, section) {
    padding: 40px 0;
    width: 100%;

    @include abstract.mq(min, tablet, sm) {
        padding: 60px 0;
    }
    
    @include abstract.inner-class(#{ __ }, title) {
        line-height: 1.3;
        margin-bottom: 30px;

        @include abstract.mq(min, tablet, sm) {
            margin-bottom: 50px;
        }
    }

    @include abstract.inner-class(#{__}, sub-nav__menu) {
        @include abstract.mq(min, tablet, sm) {
            @include abstract.getColor(background-color, blackish, dim-300--10-percent);
            border-radius: 5px;
            overflow: hidden;
            
            li {
                flex: 1;
                
                &.current, &:hover, &:focus {
                    a {
                        @include abstract.getColor(background-color, accent, source);
                        @include abstract.getColor(color, whitish, source);
                        text-decoration: none;
                        font-weight: 600;
                        transition: all 300ms ease-in-out;
                        border-radius: 5px;
                    }
                }
            }
            a {
                display: block;
                padding: 14px 0;
                text-align: center;
                font-weight: 500;
                @include abstract.getColor(color, accent, source);
                transition: all 300ms ease-in-out;
            }
        }
    }

    @include abstract.plus-attr-class(section, nobel) {
        padding-top: 10px;

        @include abstract.mq(min, tablet, sm) {
            padding-top: 20px;
        }
        // @include abstract.class-injection(nobel-section, #{ __ }, title) {
        // }
    }
    @include abstract.plus-attr-class(section '.'+nobel-section, nobel) {
        @include abstract.inner-class(#{__}, title) {
            margin-bottom: 40px;
            
            @include abstract.mq(min, tablet, sm) {
                margin-bottom: 60px;
            }
        }
    }
}