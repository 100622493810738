@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, modal) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    transition: all 500ms ease-in-out 0s;
    
    @include abstract.inner-class(#{ -- }, is-active) {
        overflow: hidden;
    }
    
    @include abstract.inner-class(#{ -- }, open) {
        visibility: visible;
        opacity: 1;
        transition: all 500ms ease-in-out 0s;
    }
    
    @include abstract.inner-class(#{ -- }, close) {
        visibility: hidden;
        opacity: 0;
        transition: all 500ms ease-in-out 0s;
    }

    @include abstract.inner-class(#{ __ }, overlay) {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    @include abstract.inner-class(#{ __ }, dialog) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        padding: 20px;

        .nobel-modal__body {
            
            max-height: 80vh;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    @include abstract.inner-class(#{__}, close-btn) {
        width: 30px;
        height: 30px;
        @include abstract.getColor(background-color, whitish, source, $imp:true);
        border-radius: 100%;
        @include abstract.shadow(0px, 4px, 4px, null, var(--clr-blackish--vr-two-dim-25-percent));
    }

    &.noble-modal--notification {
        .nobel-modal__close-btn {
            position: absolute;
            top: -10px;
            right: -10px;
        }
    }

}