@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, flex) {
    @include abstract.display(flex);

    @include abstract.inner-class(#{--}, wr-flow) {
        flex-flow: wrap row;
    }

    @include abstract.inner-class(#{--}, wc-flow) {
        flex-flow: wrap column;
    }

    @include abstract.inner-class(#{--}, ai-start) {
        align-items: flex-start;
    }

    @include abstract.inner-class(#{--}, ai-end) {
        align-items: flex-end;
    }

    @include abstract.inner-class(#{--}, ai-center) {
        align-items: center;
    }

    @include abstract.inner-class(#{--}, ai-stretch) {
        align-items: stretch;
    }

    @include abstract.inner-class(#{--}, ai-baseline) {
        align-items: baseline;
    }

    @include abstract.inner-class(#{--}, jc-start) {
        justify-content: flex-start;
    }

    @include abstract.inner-class(#{--}, jc-end) {
        justify-content: flex-end;
    }

    @include abstract.inner-class(#{--}, jc-center) {
        justify-content: center;
    }

    @include abstract.inner-class(#{--}, jc-between) {
        justify-content: space-between;
    }

    @include abstract.inner-class(#{--}, jc-around) {
        justify-content: space-around;
    }

    @include abstract.inner-class(#{--}, jc-evenly) {
        justify-content: space-evenly;
    }
}