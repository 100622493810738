@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, apply-now) {
    &.nobel-section {
        padding: 40px 0 !important;
        margin-bottom: 40px;
    
        @include abstract.mq(min, desktop, sm) {
            padding: 60px 0 !important;
            margin-bottom: 60px;
        }
    }
}