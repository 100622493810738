@use '../abstract/' as abstract;

// Mixin for prefix-class
// @include abstract.prefix-class(nobel, btn)
// @include inner-class($type: #{-}, $className: some-class-name)
// @include hover-state


@each $color, $shades in abstract.$colors {
    @each $shade, $val in $shades {
        @include abstract.prefix-class(nobel, btn--bg-#{$color}--#{$shade}) {
            @include abstract.getColor(background-color, #{$color}, #{$shade});
        }

        @include abstract.prefix-class(nobel, btn--text-#{$color}--#{$shade}) {
            @include abstract.getColor(color, #{$color}, #{$shade});
        }

        @include abstract.prefix-class(nobel, btn--outline-#{$color}--#{$shade}) {
            background-color: transparent !important;
            @include abstract.getColor(border-color, #{$color}, #{$shade});
            @include abstract.getColor(color, #{$color}, #{$shade});

            @include abstract.hover-state {
                background-color: map-get(map-get(abstract.$colors, accent), source ) !important;
                background-color: var(--clr-accent--source) !important;
                @include abstract.getColor(color, whitish, source);
            }
        }
    }
}

@include abstract.prefix-class(nobel, btn) {
    display: inline-block;
    padding: 10px 21px;
    // line-height: 1;
    border: 1px solid transparent;
    text-align: center;
    transition: all 300ms ease-in-out 0s;

    @include abstract.inner-class($type: #{--}, $className: md) {
        @include abstract.mq(min, tablet, sm) {
            min-width: 200px;
            padding: 12px 21px;
        }
    }
    

    @include abstract.inner-class($type: #{--}, $className: outline) {
        background-color: transparent;
    }
    
    @include abstract.hover-state {
        transition: all 300ms ease-in-out 0s;
        @include abstract.getColor(background-color, primary, 500);
        text-decoration: none;
        @include abstract.getColor(color, whitish, source);
    }

    @include abstract.inner-class($type: #{--}, $className: bg-primary--source) {
        @include abstract.hover-state {
            @include abstract.getColor(background-color, accent, source);
        }
    }
    &--bg-primary--source {
        background-color: map-get(map-get(abstract.$colors, primary), source ) !important;
        background-color: var(--clr-primary--source) !important;

        @include abstract.hover-state {
            background-color: map-get(map-get(abstract.$colors, accent), source ) !important;
            background-color: var(--clr-accent--source) !important;
        }
    }

    &--outline-primary--source {
        background-color: transparent !important;

        @include abstract.hover-state {
            background-color: map-get(map-get(abstract.$colors, primary), source ) !important;
            background-color: var(--clr-prinary--source) !important;
            @include abstract.getColor(color, whitish, source);
        }
    }

    @include abstract.inner-class($type: #{--}, $className: bg-whitish--source) {
        @include abstract.hover-state {
            @include abstract.getColor(background-color, primary, source);
            @include abstract.getColor(color, whitish, source);
        }
    }

    @include abstract.inner-class($type: #{--}, $className: outline) {
        background-color: transparent;

        @include abstract.same-elm-class(nobel, btn--bg-whitish--source) {
            @include abstract.getColor(border-color, whitish, source);

            @include abstract.hover-state {
                @include abstract.getColor(background-color, whitish, source);
                @include abstract.getColor(color, primary, source);
            }
        }
    }

}

@each $color, $shades in abstract.$colors {
    @each $shade, $val in $shades {
        @include abstract.prefix-class(nobel, btn--outline-#{$color}--#{$shade}) {
            // background-color: transparent !important;
            @include abstract.getColor(border-color, #{$color}, #{$shade});
            @include abstract.getColor(color, #{$color}, #{$shade});

            @include abstract.hover-state {
                background-color: map-get(map-get(abstract.$colors, accent), source ) !important;
                background-color: var(--clr-accent--source) !important;
                @include abstract.getColor(color, whitish, source);
            }
        }
    }
}