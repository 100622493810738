@use '../abstract/' as abstract;
@for $i from 1 through 12 {
    .col-#{$i}--of-12 {
        width: percentage($i / 12);
        box-sizing: content-box;
    }
}
@for $i from 1 through 12 {
    
    @include abstract.mq(min, tablet, xs) {
        .col-md-#{$i}--of-12 {
            width: percentage($i / 12);
            box-sizing: content-box;
        }

        .col-md-35--of-100 {
            width: 35%;
            box-sizing: content-box;
        }

        .col-md-39--of-100 {
            width: 39%;
            box-sizing: content-box;
        }

        .col-md-40--of-100 {
            width: 40%;
            box-sizing: content-box;
        }
    } 
}

.nobel-gutter {
    margin: 0 -15px;

    @for $i from 1 through 12 {
        .col-#{$i}--of-12 {
            padding: 0 15px;
        }
    }
    @for $i from 1 through 12 {
        
        @include abstract.mq(min, tablet, xs) {
            .col-md-#{$i}--of-12 {
                padding: 0 15px;
            }
        } 
    }
}