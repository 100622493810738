@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, testimonial) {
    article {
        @include abstract.shadow($x:0px, $y:4px, $blur:15px, $shade:null, $color: rgba(#797979, 0.4));

        @include abstract.mq(min, tablet, sm) {
            min-height: 355px;
        }
    }
}