@use '../abstract/' as abstract;
.nobel-header {
    @include abstract.getShadow(box-shadow, header, source-1);
    &__navigation__top {

        @include abstract.pseudo($loc: after, $cnt: null, $pos: absolute) {
            top: 0;
            left: 100%;
            z-index: 1;

            width: 200%;
            height: 100%;
            @include abstract.getColor(background-color, primary, source);
        }
    }

    &__menu {
        & > li + li {
            @include abstract.mq(min, tablet, xs) {
                margin-left: 30px;
            }
            @include abstract.mq(min, desktop, md) {
                margin-left: 50px;
            }
        }
        a {
            line-height: 1;
            @include abstract.fetchGlobalVars(font-family, typography, abstract.$fonts, family, mulish);
        }
        
        li {
            a > .fa, a > .mdi {
                display: none;
            }

            &.nobel-dropdown {
                a > .fa, a > .mdi {
                    display: inline-block;
                }   
            }
        }
    }
}