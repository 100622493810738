@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, collaboration-list) {
    
}

@include abstract.prefix-class(nobel, paragraph) {
    margin-top: 28px;
    p + p {
        margin-top: 1.2rem;
    }
    p + ol, p + ul, ol + p, ul + p {
        margin-top: 1.3rem;
    }

    li + li {
        margin-top: 1rem;
    }

    ol + h1, ul + h1,
    ol + h2, ul + h2,
    ol + h3, ul + h3,
    ol + h4, ul + h4,
    ol + h5, ul + h5,
    ol + h6, ul + h6,
    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
        margin-top: 2rem;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }
}