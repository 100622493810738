@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, accordion) {
    @include abstract.inner-class(#{__}, content) {
        transition: all 500ms ease-in-out 0s;
    }

    &.nobel-accordion--open {
        .nobel-accordion__content {
            transition: all 500ms ease-in-out 0s;
        }
    }
}