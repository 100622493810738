@use '../abstract/' as abstract;

@include abstract.prefix-class(nobel, affiliation) {
    padding-bottom: 30px !important;

    @include abstract.inner-class(#{ __ }, logo) {
        flex: 1;
        min-width: 25%;
        margin-bottom: 30px;

        img {
            margin: 0 auto;
        }
    }
}